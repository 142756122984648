import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { isValidPhoneNumber } from 'libphonenumber-js';

export abstract class Utility {
  public static phoneNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      return !isValidPhoneNumber(control.value.trim())
        ? { invalidPhoneNumber: true }
        : null;
    };
  }
}
