import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js';

@Pipe({
  name: 'formatPhoneNumber',
})
export class FormatPhoneNumberPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return '';
    const phoneNumber = parsePhoneNumber(`+${value}`);
    return phoneNumber.formatInternational();
  }
}
