import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IvrPageStateEnum } from 'src/app/models/enums/ivrPageState.enum';
import { IvrProcessStatus } from 'src/app/models/enums/IvrProcessStatus.enum';
import { Ivr } from 'src/app/models/ivr.model';
import { FullstoryService } from 'src/app/services/fullstory.service';
import { IvrService } from 'src/app/services/ivr.service';
import { IvrDatasourceService } from 'src/app/shared/data/ivr-datasource.service';
import { TrackerEvent } from 'src/app/shared/utilities/tracker';

@Component({
  selector: 'rmi-ivr-analysis-report',
  templateUrl: './ivr-analysis-report.component.html',
  styleUrls: ['./ivr-analysis-report.component.scss'],
})
export class IvrAnalysisReportComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  ivrPageStateEnum = IvrPageStateEnum;
  ivrPageState: IvrPageStateEnum | undefined;

  constructor(
    private route: ActivatedRoute,
    private ivrService: IvrService,
    public ivrDataSource: IvrDatasourceService,
    private fullstoryService: FullstoryService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.fullstoryService.fullstoryInit.subscribe({
      next: () =>
        this.fullstoryService.trackerEvent(TrackerEvent.ROUTE_PAGE_REPORT),
    });
    // ignore loading if is ssr
    if(!isPlatformBrowser(this.platformId)) return;
    const routeParams = this.route.snapshot.paramMap;
    const ivrId: string = routeParams.get('ivrId') || '';
    this.ivrService
      .getIvr(ivrId)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (ivr: Ivr) => {
          this.ivrDataSource.ivr$.next(ivr);
          this.ivrPageState = this.getIvrPageState(ivr);
          this.ivrDataSource.ivrPageState$.next(this.ivrPageState);
          this.ivrDataSource.loading$.next(false);
          this.ivrDataSource.showEditReportButtons$.next(ivr.canEditReport);
        },
        error: (error) => {
          const { status } = error;
          if (status === 410) {
            this.ivrPageState = IvrPageStateEnum.Expired;
            this.ivrDataSource.ivrPageState$.next(this.ivrPageState);
          }
          this.ivrDataSource.loading$.next(false);
        },
      });
  }

  getIvrPageState(ivr: Ivr): IvrPageStateEnum {
    switch (ivr.processStatus) {
      case IvrProcessStatus.Completed:
        return IvrPageStateEnum.Completed;
      case IvrProcessStatus.InProgress:
        return IvrPageStateEnum.InProgress;
      case IvrProcessStatus.PendingApproval:
        return IvrPageStateEnum.PendingApproval;
      case IvrProcessStatus.TimedOut:
        return IvrPageStateEnum.TimedOut;
      case IvrProcessStatus.Failed: {
        if (!ivr.isIvr) {
          return IvrPageStateEnum.IsNotIvr;
        }
        return IvrPageStateEnum.Failed;
      }
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
