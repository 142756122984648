import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IvrDatasourceService } from '../../data/ivr-datasource.service';

@Component({
  selector: 'rmi-add-report-detail-button',
  templateUrl: './add-report-detail-button.component.html',
  styleUrls: ['./add-report-detail-button.component.scss']
})
export class AddReportDetailButtonComponent implements OnInit, OnDestroy  {
  @Output() openRelatedEditModal: EventEmitter<void> = new EventEmitter();
  private readonly destroy$ = new Subject<void>();
  show = false;

  constructor(private ivrDataSource: IvrDatasourceService) {}

  ngOnInit(): void {
    this.ivrDataSource.showEditReportButtons$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (showEditReportButton: boolean) => (this.show = showEditReportButton)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

}
