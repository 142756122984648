import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { IvrAnalysisReportRoutingModule } from './ivr-analysis-report-routing.module';
import { IvrAnalysisReportComponent } from './ivr-analysis-report.component';
import { ReportStateComponent } from './report-state/report-state.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { IvrReportSkeletonComponent } from './ivr-report-skeleton/ivr-report-skeleton.component';
import { ReportModule } from './report/report.module';
import { NotFoundComponent } from "./not-found/not-found.component";
import { LandingComponent } from "./landing/landing.component";

@NgModule({
  declarations: [
    IvrAnalysisReportComponent,
    ReportStateComponent,
    IvrReportSkeletonComponent,
    NotFoundComponent,
    LandingComponent
  ],
  imports: [
    CommonModule,
    IvrAnalysisReportRoutingModule,
    SharedModule,
    ReportModule,
    NgxSkeletonLoaderModule,
  ],
  providers: [DatePipe],
  exports: [IvrAnalysisReportComponent],
})
export class IvrAnalysisReportModule {}
