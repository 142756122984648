<div
  class="d-flex flex-column align-items-center bg-white rounded-3 position-relative modal-warper"
>
  <img
    (click)="dismissModal()"
    class="cross-btn"
    width="18"
    height="18"
    src="/assets/cross.svg"
  />
  <h2
    i18n="@@common.reportProblem"
    class="mt-40px mb-4 text-center d-inline-block header"
  >
    Report a problem
  </h2>
  <div class="w-100 p-3" *ngIf="state$ | async as state">
    <form
      class="form p-32px"
      [formGroup]="form"
      (ngSubmit)="submit()"
      *ngIf="
        state === formState.LOADING ||
        state === formState.READY_FOR_SUBMISSION ||
        state === formState.ERROR
      "
    >
      <div class="field pb-0">
        <label for="email" class="form-label">Message:</label>
        <textarea
          (input)="onInput()"
          (blur)="onBlur()"
          class="form-control"
          id="message"
          formControlName="message"
        >
        </textarea>
      </div>
      <div class="field d-flex flex-row align-items-start py-4">
        <input
          type="checkbox"
          id="sendAnswerToOriginalEmail"
          class="form-control flex-shrink-0 mt-1"
          formControlName="sendAnswerToOriginalEmail"
        />
        <label
          for="sendAnswerToOriginalEmail"
          class="ms-2 cursor-pointer"
          i18n="@@contactForm.sendAnswerToOriginalEmail"
          >Send an answer to original contact email</label
        >
      </div>
      <div class="d-flex justify-content-center">
        <button
          *ngIf="
            state === formState.READY_FOR_SUBMISSION ||
            state === formState.ERROR
          "
          class="btn primary text-center"
          type="submit"
        >
          <span i18n="@@common.fixIt">Fix it!</span>
        </button>
        <button
          *ngIf="state === formState.LOADING"
          class="btn primary disabled"
          disabled
        >
          <span
            class="d-flex align-items-center justify-content-center button-wrapper"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </span>
        </button>
      </div>
      <div
        class="d-felx mt-4 unknown-error text-center"
        *ngIf="state === formState.ERROR"
      >
        <div *ngIf="errorMessage !== undefined; else defaultError">
          {{ errorMessage }}
        </div>
        <ng-template i18n="@@contactForm.unknownError" #defaultError>
          Something went wrong, please try again.
        </ng-template>
      </div>
      <div
        class="error-text error-container"
        *ngIf="
          form.controls.message.touched &&
          form.controls.message.errors?.required
        "
        i18n="@@contactForm.messageRequired"
      >
        Message is required
      </div>
    </form>
    <div
      class="submit-success d-flex flex-column align-items-center"
      *ngIf="state === formState.SUBMITTED"
    >
      <img
        src="/assets/success.svg"
        alt="Form sent"
        i18n-alt="@@common.formSent"
        (load)="onImageLoad()"
        class="mt-32px mb-4"
      />
      <div
        class="d-flex flex-column text-center pt-0 p-32px"
        *ngIf="formSentImageLoaded"
      >
        <span class="title" i18n="@@common.thankForMessage"
          >Thank you for message!</span
        >
        <span class="subtitle" i18n="@@common.tryToSolveProblemDesc"
          >We will try to solve this problem as soon as possible.</span
        >

        <button
          class="btn outline align-self-center mt-4"
          i18n="@@common.backToReport"
          (click)="closeModal()"
        >
          Back to the report
        </button>
      </div>
    </div>
  </div>
</div>
