import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IvrAnalysisReportComponent } from './ivr-analysis-report.component';
import {NotFoundComponent} from "./not-found/not-found.component";
import {LandingComponent} from "./landing/landing.component";

const routes: Routes = [
  {
    path: '',
    component: LandingComponent,
    data: {
      title: 'report',
    },
  },
  {
    path: 'ivr/:ivrId',
    component: IvrAnalysisReportComponent,
    data: {
      title: 'report',
    },
  },
  {
    path: '**',
    pathMatch: 'full',
    component: NotFoundComponent,
    data: {
      title: 'not found',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class IvrAnalysisReportRoutingModule {}
