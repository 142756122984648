<div
  class="w-100 d-flex flex-column align-items-center align-items-md-start improvement-container rounded-2"
>
  <h2 i18n="@@ivr.improvement.title" class="m-0 text-center text-md-start">
    How about improvements?
  </h2>
  <p i18n="@@ivr.improvement.desc" class="m-0 mt-4 text-center text-md-start">
    Contact us to organise a meeting and discuss the changes to improve your
    IVR.
  </p>
  <div class="my-4 bg-white rounded-2 dividing-line w-100"></div>
  <div
    class="d-flex flex-column flex-md-row align-items-center align-items-md-start"
  >
    <img
      class="rounded-circle"
      width="120"
      height="120"
      src="/assets/amir-portrait.jpg"
    />
    <div
      class="d-flex flex-column align-items-center align-items-md-start ms-0 ms-md-32px mt-md-0 mt-3"
    >
      <h3>Amir Moghimi</h3>
      <a href="mailto:roastmyivr@whitefox.cloud" class="link my-3">
        <i class="material-icons-round">mail_outline</i>
        <span>roastmyivr@whitefox.cloud</span></a
      >
      <a [href]="'tel:' + '+61 493 407 491'" class="link">
        <i class="material-icons-round">phone</i>
        <span>+61 493 407 491</span></a
      >
    </div>
  </div>
</div>
