import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ScrollerService {
  constructor() {}

  public static scrollToId(id: string = 'contact-us'): void {
    document.getElementById(id)?.scrollIntoView({
      behavior: 'smooth',
    });
  }
}
