import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { WaveformComponent } from './components/waveform/waveform.component';
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { CookiePolicyComponent } from './components/cookie-policy/cookie-policy.component';
import { FormatAudioPlayerTimePipe } from './pipes/formatAudioPlayerTime.pipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { ReportStatusComponent } from './components/report-status/report-status.component';
import { FormatPhoneNumberPipe } from './pipes/formatPhoneNumber.pipe';
import { DigitOnlyDirective } from './directives/digitOnly.directive';
import { RouterModule } from '@angular/router';
import { ScoreComponent } from './components/score/score.component';
import { AnalysisShapeComponent } from './components/analysis-shape/analysis-shape.component';
import { IvrAnalysisReportFooterComponent } from './components/ivr-analysis-report-footer/ivr-analysis-report-footer.component';
import { IvrAnalysisReportHeaderComponent } from './components/ivr-analysis-report-header/ivr-analysis-report-header.component';
import { ImprovementRequestComponent } from './components/modals/improvement-request/improvement-request.component';
import { ReportProblemComponent } from './components/modals/report-problem/report-problem.component';
import { ReportSharingComponent } from './components/modals/report-sharing/report-sharing.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApiKeyInterceptor } from './interceptor/api-key.interceptor';
import { ContactUsComponent } from './components/modals/contact-us/contact-us.component';
import { EditOverallReportScoreComponent } from './components/modals/edit-overall-report-score/edit-overall-report-score.component';
import { EditSpeechQualityStatusComponent } from './components/modals/edit-speech-quality-status/edit-speech-quality-status.component';
import { EditComplexityReportStatusComponent } from './components/modals/edit-complexity-report-status/edit-complexity-report-status.component';
import { EditSoundQualityNoiseComponent } from './components/modals/edit-sound-quality-noise/edit-sound-quality-noise.component';
import { EditSoundQualityMusicComponent } from './components/modals/edit-sound-quality-music/edit-sound-quality-music.component';
import { EditSoundQualityLoudnessComponent } from './components/modals/edit-sound-quality-loudness/edit-sound-quality-loudness.component';
import { EditDiscoveredMenuContextComponent } from './components/modals/edit-discovered-menu-context/edit-discovered-menu-context.component';
import { EditRecommendationsComponent } from './components/modals/edit-recommendations/edit-recommendations.component';
import { EditQualitativeAnalysisComponent } from './components/modals/edit-qualitative-analysis/edit-qualitative-analysis.component';
import { EditReportButtonByAdminComponent } from './components/edit-report-button-by-admin/edit-report-button-by-admin.component';
import { ReportEditorNotesComponent } from './components/modals/report-editor-notes/report-editor-notes.component';
import { SaveEditorReportChangesComponent } from './components/modals/save-editor-report-changes/save-editor-report-changes.component';
import { QuillModule } from 'ngx-quill';
import { TokenInterceptor } from './interceptor/token.interceptor';
import { RemoveReportDetailButtonComponent } from './components/remove-report-detail-button/remove-report-detail-button.component';
import { AddNewMenuComponent } from './components/modals/add-new-menu/add-new-menu.component';
import { AddReportDetailButtonComponent } from './components/add-report-detail-button/add-report-detail-button.component';
import { AddNoteButtonComponent } from './components/add-note-button/add-note-button.component';
import { SaveChangesButtonComponent } from './components/save-changes-button/save-changes-button.component';
@NgModule({
  declarations: [
    WaveformComponent,
    CircleProgressComponent,
    ReportStatusComponent,
    FormatAudioPlayerTimePipe,
    FormatPhoneNumberPipe,
    SafeHtmlPipe,
    DigitOnlyDirective,
    ScoreComponent,
    AnalysisShapeComponent,
    CookiePolicyComponent,
    IvrAnalysisReportFooterComponent,
    IvrAnalysisReportHeaderComponent,
    ImprovementRequestComponent,
    ReportSharingComponent,
    ReportProblemComponent,
    ContactUsComponent,
    EditOverallReportScoreComponent,
    EditSpeechQualityStatusComponent,
    EditComplexityReportStatusComponent,
    EditSoundQualityNoiseComponent,
    EditSoundQualityMusicComponent,
    EditSoundQualityLoudnessComponent,
    EditDiscoveredMenuContextComponent,
    EditRecommendationsComponent,
    EditQualitativeAnalysisComponent,
    EditReportButtonByAdminComponent,
    ReportEditorNotesComponent,
    SaveEditorReportChangesComponent,
    RemoveReportDetailButtonComponent,
    AddNewMenuComponent,
    AddReportDetailButtonComponent,
    AddNoteButtonComponent,
    SaveChangesButtonComponent,
  ],
  imports: [
    CommonModule,
    NgCircleProgressModule.forRoot(),
    RouterModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule,
    NgSelectModule,
    QuillModule.forRoot(),
  ],
  exports: [
    WaveformComponent,
    CircleProgressComponent,
    ReportStatusComponent,
    FormatAudioPlayerTimePipe,
    FormatPhoneNumberPipe,
    SafeHtmlPipe,
    DigitOnlyDirective,
    ScoreComponent,
    AnalysisShapeComponent,
    CookiePolicyComponent,
    IvrAnalysisReportFooterComponent,
    IvrAnalysisReportHeaderComponent,
    ImprovementRequestComponent,
    ReportSharingComponent,
    ReportProblemComponent,
    EditReportButtonByAdminComponent,
    RemoveReportDetailButtonComponent,
    AddReportDetailButtonComponent,
    AddNoteButtonComponent,
    SaveChangesButtonComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiKeyInterceptor,
      multi: true,
    },
  ],
})
export class SharedModule {}
