import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (!req.headers.has('Authorization') && !req.headers.has('skip')) {
      return this.validateAccessToken().pipe(
        switchMap((value) => {
          const tokenizedReq = req.clone({
            setHeaders: {
              Authorization: value,
            },
          });
          return next.handle(tokenizedReq);
        })
      );
    } else {
      if (req.headers.has('skip')) {
        req = req.clone({
          headers: req.headers.delete('skip'),
        });
      }
      return next.handle(req);
    }
  }

  validateAccessToken(): Observable<string> {
    return new Observable<string>((subscriber) => {
      const ivrAuth = this.cookieService.get(environment.authCookieName) || null;
      try {
        subscriber.next(`Bearer ${ivrAuth}`);
        subscriber.complete();
      } catch (_) {
        subscriber.next('');
        subscriber.complete();
      }
    });
  }
}
