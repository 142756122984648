import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatAudioPlayerTime',
})
export class FormatAudioPlayerTimePipe implements PipeTransform {
  transform(value: number | undefined): string {
    if (value) {
      return (
        Math.floor(value / 60) + ':' + ('0' + Math.floor(value % 60)).slice(-2)
      );
    }
    return '0:00';
  }
}
