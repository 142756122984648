import { Component, Input } from '@angular/core';

import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'rmi-ivr-analysis-report-header',
  templateUrl: './ivr-analysis-report-header.component.html',
  styleUrls: ['./ivr-analysis-report-header.component.scss'],
})
export class IvrAnalysisReportHeaderComponent {
  @Input() showSkeleton: boolean = false;
  @Input() showEmail: boolean = true;
  constructor(private modalService: ModalService) {}

  openReportSharingModal() {
    this.modalService.openReportSharingModal();
  }
}
