<div
  class="custom-container footer w-100 d-flex align-items-center justify-content-xxl-center py-32px"
>
  <div
    class="w-100 d-flex flex-column align-items-center align-items-md-start flex-lg-row align-items-lg-center justify-content-lg-between"
  >
    <div class="d-flex flex-md-row flex-column">
      <div class="d-flex align-items-center flex-md-row flex-column">
        <img src="/assets/whitefox-logo.svg" alt="Whitefox logo" />
        <a
          class="cursor-pointer text-decoration-none text-white mt-md-0 mt-4 ms-md-4 ms-0"
          href="https://whitefox.cloud"
          target="_blank"
          >
          <span class="footer__created-by-text" i18n="@@footer.createdBy">
            © <span>{{year}}</span> Created by WhiteFox
          </span>
        </a>
      </div>
      <div class="d-flex align-items-center a mt-md-0 mt-4 ms-md-4 ms-0">
        <a
          class="footer-link footer-link-small"
          (click)="goToTermsOfService()"
          ><span i18n="@@common.tos">Terms of service</span>
        </a>
        <a
          class="footer-link footer-link-small ms-4"
          (click)="goToPrivacyPolicyPage()"
          ><span i18n="@@common.policy">Privacy policy</span></a
        >
      </div>
    </div>
    <div class="footer__report-problem mt-lg-0 mt-md-10px mt-md-0 mt-4">
      <div class="d-flex align-items-center">
        <a class="footer-link" (click)="openReportProblemModal()">
          <i class="material-icons-round">warning_amber</i
          ><span i18n="@@common.reportProblem">Report a problem</span></a
        >
      </div>
    </div>
  </div>
</div>
