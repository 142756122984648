import { Component, Input } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Ivr } from 'src/app/models/ivr.model';
import { Menu } from 'src/app/models/menu.model';
import { ModalService } from 'src/app/services/modal.service';
import { ScrollerService } from 'src/app/services/scroller.service';
import { IvrDatasourceService } from 'src/app/shared/data/ivr-datasource.service';

@Component({
  selector: 'rmi-discovered-menu',
  templateUrl: './discovered-menu.component.html',
  styleUrls: ['./discovered-menu.component.scss'],
})
export class DiscoveredMenuComponent {
  private readonly destroy$ = new Subject<void>();
  // @ts-ignore
  @Input() ivr: Ivr;
  isDeleteButtonVisible: boolean = false;
  menuItemIdHover: string = '';
  show = false;

  constructor(
    private ivrDataSource: IvrDatasourceService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => (this.ivr = ivr));

    this.ivrDataSource.showEditReportButtons$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (showDeleteReportButton: boolean) =>
          (this.show = showDeleteReportButton)
      );
  }

  scroll(elementId: string) {
    ScrollerService.scrollToId(elementId);
  }

  openReportProblemModal() {
    this.modalService.openReportProblemModal();
  }

  openEditMenuContextModal(menuId: string) {
    this.modalService.openEditMenuContextModal(menuId);
  }

  openAddNewMenuModal(menuId: string) {
    this.modalService.openAddNewMenuModal(menuId);
  }

  mouseEnter(menuItemId: string) {
    this.menuItemIdHover = menuItemId;
    this.isDeleteButtonVisible = true;
  }

  mouseLeave() {
    this.menuItemIdHover = '';
    this.isDeleteButtonVisible = false;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
