export enum IvrPageStateEnum {
  InProgress = 'InProgress',
  Completed = 'Completed',
  PendingApproval = 'PendingApproval',
  Failed = 'Failed',
  TimedOut = 'TimedOut',
  IsNotIvr = 'IsNotIvr',
  Expired = 'Expired',
  Error = 'Error',
}
