import { IvrDatasourceService } from '../../../data/ivr-datasource.service';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormState } from 'src/app/models/enums/formState.enum';
import { Ivr } from 'src/app/models/ivr.model';
import { Menu } from 'src/app/models/menu.model';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'rmi-edit-discovered-menu-context',
  templateUrl: './edit-discovered-menu-context.component.html',
  styleUrls: ['./edit-discovered-menu-context.component.scss'],
})
export class EditDiscoveredMenuContextComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  private readonly destroy$ = new Subject<void>();
  formState = FormState;
  public state$ = new BehaviorSubject(FormState.READY_FOR_SUBMISSION);
  // @ts-ignore
  @Input() ivr: Ivr;
  @Input() menuId: string = '';

  // @ts-ignore
  form: FormGroup;
  errorMessage: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private ivrDataSource: IvrDatasourceService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit() {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => {
        this.form = this.fb.group({
          menuContext: [
            this.getMenuText(ivr.ivrReportVersion!.ivrMenu, this.menuId),
            [Validators.required],
          ],
        });
        this.ivr = ivr;
      });
  }

  getMenuText(menu: Menu | null, menuId: string): string {
    if (menu) {
      if (menu.menuId === menuId) {
        return menu.englishTextHighlightedInputKeys || '';
      } else {
        for (const menuItem of menu.menuItems) {
          const result = this.getMenuText(menuItem.menu, menuId);
          if (result && result.length > 0) {
            return result;
          }
        }
      }
    }
    return '';
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();

      this.setMenuText(
        this.ivr.ivrReportVersion!.ivrMenu,
        this.form.controls['menuContext'].value,
        this.menuId
      );
      this.ivrDataSource.ivr$.next(this.ivr);
      this.closeModal();
    }
  }

  setMenuText(menu: Menu | null, text: string, menuId: string): void {
    if (menu) {
      if (menu.menuId === menuId) {
        menu.englishTextHighlightedInputKeys = text;
      } else {
        for (const menuItem of menu.menuItems) {
          this.setMenuText(menuItem.menu, text, menuId);
        }
      }
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onInput(): void {
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res === 'INVALID') {
        this.form.markAllAsTouched();
      }
    });
  }

  onBlur(): void {
    this.form.markAsUntouched();
  }

  customizingQuill() {
    import('quill').then(({ default: Quill }) => {
      const InlineBlot = Quill.import('blots/inline');
      class Mark extends InlineBlot {
        static create() {
          return super.create();
        }
      }
      Mark.blotName = 'mark';
      Mark.tagName = ['MARK'];
      Quill.register(Mark);
      let markBtn = document.getElementsByClassName('ql-mark');
      setTimeout(() => {
        markBtn[0].innerHTML = `<svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <defs><style>.cls-1{fill:#434343;}</style></defs>
      <path class="cls-1" d="M3,3.73H6.28l4.15,7.53c.52,1,1,2,1.54,2.93h.13c.52-1,1-2,1.5-2.93l4.11-7.53H21V17.82H18.46V10.06c0-1.23.21-2.92.35-4.17h-.13L17,9.11l-4.08,7.28H11.05L7,9.11,5.24,5.89H5.11c.13,1.25.35,2.94.35,4.17v7.76H3Z"/>
      </svg>`;
      }, 100);
    });
  }

  module = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike', 'mark'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button

      ['link'], // link and image, video
    ],
  };
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.customizingQuill();
    }
  }
}
