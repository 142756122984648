<div class="d-flex w-100 flex-column">
  <h2 i18n="@@ivr.discoveredMenu" class="title mb-4">Discovered Menu</h2>
  <ul class="tree p-md-32px p-3 m-0 rounded-top-3">
    <div class="menu d-flex flex-column mb-2">
      <p class="menu__text">
        <span i18n="@@ivr.testStarted">Test started, dialing</span>&nbsp;
        <strong [innerHTML]="ivr.telephoneNumber | formatPhoneNumber"></strong>
      </p>
    </div>

    <rmi-menu-ivr-tree
      [menu]="ivr.ivrReportVersion?.ivrMenu!"
      path="home"
    ></rmi-menu-ivr-tree>
  </ul>
  <div
    class="rounded-bottom-3 mt-2px report-problem d-flex justify-content-center align-items-center"
  >
    <a
      class="link w-100 text-center justify-content-center"
      (click)="openReportProblemModal()"
    >
      <i class="material-icons-round">warning_amber</i>
      <span i18n="@@common.reportProblem">Report a problem</span></a
    >
  </div>
</div>
