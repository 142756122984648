import { Component, Input, OnInit } from '@angular/core';
import { ReportStatus } from 'src/app/models/report.model';

@Component({
  selector: 'rmi-score',
  templateUrl: './score.component.html',
  styleUrls: ['./score.component.scss'],
})
export class ScoreComponent {
  @Input() large: boolean = false;
  @Input() reportStatus: ReportStatus | undefined;
  @Input() soundQualityRating: boolean = false;

  constructor() {}
}
