<div
  class="d-flex flex-column align-items-center bg-white rounded-3 position-relative modal-warper"
>
  <img
    (click)="dismissModal()"
    class="cross-btn"
    width="18"
    height="18"
    src="/assets/cross.svg"
  />
  <h2
    i18n="@@contactForm.saveAllChanges"
    class="mt-40px mb-4 text-center d-inline-block header"
  >
    Save All Changes
  </h2>
  <div class="w-100 p-3" *ngIf="state$ | async as state">
    <form
      class="form p-32px"
      [formGroup]="form"
      (ngSubmit)="submit()"
      *ngIf="
        ivr &&
        (state === formState.LOADING ||
          state === formState.READY_FOR_SUBMISSION ||
          state === formState.ERROR)
      "
    >
      <div class="field">
        <label for="versionName" class="form-label">Version Name:</label>
        <input type="text" class="form-control" formControlName="versionName" />
      </div>
      <div class="field d-flex flex-row align-items-start py-2">
        <input
          type="checkbox"
          id="editCurrentVersion"
          class="form-control flex-shrink-0 mt-1"
          formControlName="editCurrentVersion"
          (change)="toggleCurrentVersion($event)"
        />
        <label
          for="editCurrentVersion"
          class="form-label"
          i18n="@@contactForm.editCurrentVersion"
          >Edit current version
        </label>
      </div>
      <div class="field d-flex flex-row align-items-start py-2">
        <input
          type="checkbox"
          id="setDefaultVersion"
          class="form-control flex-shrink-0 mt-1"
          formControlName="setDefaultVersion"
        />
        <label
          for="setDefaultVersion"
          class="form-label"
          i18n="@@contactForm.setAsDefault"
          >Set as default report</label
        >
      </div>
      <div class="d-flex justify-content-center">
        <button
          *ngIf="
            state === formState.READY_FOR_SUBMISSION ||
            state === formState.ERROR
          "
          class="btn primary text-center"
          type="submit"
        >
          <span i18n="@@common.save">Save</span>
        </button>
        <button
          *ngIf="state === formState.LOADING"
          class="btn primary disabled"
          disabled
        >
          <span
            class="d-flex align-items-center justify-content-center button-wrapper"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </span>
        </button>
      </div>
    </form>
    <div
      class="submit-success d-flex flex-column align-items-center"
      *ngIf="state === formState.SUBMITTED"
    >
      <img
        src="/assets/success.svg"
        alt="Form sent"
        i18n-alt="@@common.formSent"
        (load)="onImageLoad()"
        class="mt-32px mb-4"
      />
      <div
        class="d-flex flex-column text-center pt-0 p-32px"
        *ngIf="formSentImageLoaded"
      >
        <button
          class="btn outline align-self-center mt-4"
          i18n="@@common.backToReport"
          (click)="closeModal()"
        >
          Back to the report
        </button>
      </div>
    </div>
  </div>
</div>
