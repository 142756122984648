<div
  class="d-flex flex-column align-items-center bg-white rounded-3 position-relative modal-warper"
>
  <img
    (click)="dismissModal()"
    class="cross-btn"
    width="18"
    height="18"
    src="/assets/cross.svg"
  />
  <h2
    i18n="@@common.editQualitativeAnalysis"
    class="mt-40px mb-4 text-center d-inline-block header"
  >
    Edit Qualitative Analysis
  </h2>
  <div class="w-100 p-3">
    <form
      *ngIf="ivr"
      class="form p-32px"
      [formGroup]="form"
      (ngSubmit)="submit()"
    >
      <div class="field">
        <label for="analysis" class="form-label">Analysis:</label>
        <textarea
          (input)="onInput()"
          (blur)="onBlur()"
          class="form-control"
          id="analysis"
          formControlName="text"
        >
        </textarea>
      </div>
      <div class="field d-flex flex-row align-items-start py-2">
        <input
          type="checkbox"
          id="hasAnyIssue"
          class="form-control flex-shrink-0 mt-1"
          formControlName="hasIssue"
          (change)="onHasIssueChange($event)"
        />
        <label
          for="hasAnyIssue"
          class="form-label ms-2"
          i18n="@@contactForm.hasIssues"
          >Has any issues:</label
        >
      </div>
      <div *ngIf="hasIssueChecked" class="field">
        <label for="numberOfIssues" class="form-label">Number of issues:</label>
        <input
          id="numberOfIssues"
          type="text"
          digitOnly
          class="form-control"
          formControlName="numberOfIssues"
        />
      </div>
      <div class="field">
        <label for="description" class="form-label">Description:</label>
        <input type="text" class="form-control" formControlName="description" />
      </div>
      <div class="field">
        <label for="analysisPriority" class="form-label">Priority:</label>
        <ng-select
          class="custom-ng-select mt-1 w-100"
          id="analysisPriority"
          placeholder="Select the Priority "
          formControlName="analysisPriority"
        >
          <ng-option
            *ngFor="
              let reportRecommendationsOrderOption of reportRecommendationsOrderOptions;
              trackBy: identify
            "
            [value]="reportRecommendationsOrderOption.value"
            >{{ reportRecommendationsOrderOption.key }}</ng-option
          >
        </ng-select>
      </div>
      <div class="d-flex justify-content-center">
        <button class="btn primary text-center" type="submit">
          <span i18n="@@common.save">Save</span>
        </button>
      </div>
    </form>
  </div>
</div>
