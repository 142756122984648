<circle-progress
  *ngIf="reportStatus.statusText !== 'Not Available'; else notAvailable"
  [percent]="reportStatus.percent!"
  [maxPercent]="100"
  [showInnerStroke]="false"
  [showSubtitle]="false"
  [showUnits]="false"
  [backgroundPadding]="0"
  [outerStrokeWidth]="10"
  titleFontSize="60"
  titleFontWeight="600"
  unitsFontSize="60"
  unitsFontWeight="600"
  [outerStrokeColor]="reportStatus.color"
  [backgroundColor]="reportStatus.colorWithOpacity"
  [titleColor]="reportStatus.color"
  [unitsColor]="reportStatus.color"
  [animation]="true"
  [animationDuration]="300"
  class="w-100 h-100"
></circle-progress>
<ng-template #notAvailable>
  <div
    #notAvailable
    class="d-flex rounded-circle align-items-center"
  >
    <img
      [height]="100"
      [width]="100"
      alt="not available"
      src="/assets/not-available-speech-quality.svg"
    /></div
></ng-template>
