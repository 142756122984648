import { Component, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { Industry } from 'src/app/models/industry.model';
import { Ivr } from 'src/app/models/ivr.model';
import { IvrDatasourceService } from 'src/app/shared/data/ivr-datasource.service';
import { map, takeUntil } from 'rxjs/operators';
import { IvrProvider } from 'src/app/models/ivrProvider.model';
import { IvrProviderService } from 'src/app/services/ivr-provider.service';
import { Collection } from 'src/app/models/collection';
import { FormBuilder, Validators } from '@angular/forms';
import { FormState } from 'src/app/models/enums/formState.enum';
import { ModalService } from 'src/app/services/modal.service';
import { IvrReportVersion } from 'src/app/models/ivrReportVersion.model';

@Component({
  selector: 'rmi-detailed-analysis',
  templateUrl: './detailed-analysis.component.html',
  styleUrls: ['./detailed-analysis.component.scss'],
})
export class DetailedAnalysisComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  // @ts-ignore
  ivrReportVersion: IvrReportVersion;
  // @ts-ignore
  industry: Industry;
  lastIvrProvider: string = '';
  ivrId: string = '';
  showReport: boolean = true;
  canAddProvider: boolean = false;
  // @ts-ignore
  ivrProviders$: Observable<IvrProvider[]>;
  errorMessage?: string;

  get showChoosingProvider(): boolean {
    return (
      (!this.showReport || (this.showReport && !this.lastIvrProvider)) &&
      this.canAddProvider
    );
  }

  get showEditingProvider(): boolean {
    return this.showReport && !!this.lastIvrProvider;
  }

  form = this.fb.group({
    provider: [null, [Validators.required]],
  });
  public state$ = new BehaviorSubject(FormState.READY_FOR_SUBMISSION);
  formState = FormState;

  constructor(
    private ivrDataSource: IvrDatasourceService,
    private ivrProviderService: IvrProviderService,
    private fb: FormBuilder,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.getIvrProviders();
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => {
        this.industry = ivr.industry;
        this.lastIvrProvider = ivr.ivrProvider;
        this.ivrId = ivr.ivrId;
        this.canAddProvider = ivr.canAddProvider;
        this.ivrReportVersion = ivr.ivrReportVersion!;
      });
  }

  public getIvrProviders(): void {
    this.ivrProviders$ = this.ivrProviderService.getIvrProviders().pipe(
      map((ivrProviderCollection: Collection<IvrProvider>) => {
        const provides = ivrProviderCollection.members.sort((a, b) =>
          a.providerName.localeCompare(b.providerName)
        );
        // put 'I don't now' in last place
        const fromIndex = provides.findIndex((x) => x.providerId === 1000);
        const element = provides.splice(fromIndex, 1)[0];
        provides.splice(provides.length, 0, element);
        return provides;
      })
    );
  }

  toggleShowReport() {
    this.showReport = !this.showReport;
  }

  addProviderToIvr() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();
      this.state$.next(FormState.LOADING);
      const providerName: string = this.form.controls['provider'].value;
      this.ivrProviderService
        .addProviderToIvr(this.ivrId, providerName)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: { providerName: string }) => {
            this.lastIvrProvider = data.providerName;
          },
          complete: () => {
            this.form.enable();
            this.state$.next(FormState.READY_FOR_SUBMISSION);
            this.showReport = true;
          },
          error: ({ error }) => {
            if (typeof error === 'string') this.errorMessage = error;
            this.state$.next(FormState.ERROR);
            this.form.enable();
          },
        });
    }
  }

  openEditSpeechQualityStatusModal() {
    this.modalService.openEditSpeechQualityStatusModal();
  }

  openEditComplexityReportStatusModal() {
    this.modalService.openEditComplexityReportStatusModal();
  }

  openEditSoundQualityNoiseScoreModal() {
    this.modalService.openEditSoundQualityNoiseScoreModal();
  }

  openEditSoundQualityMusicScoreModal() {
    this.modalService.openEditSoundQualityMusicScoreModal();
  }

  openEditSoundQualityLoudnessScoreModal() {
    this.modalService.openEditSoundQualityLoudnessScoreModal();
  }

  openQualitativeAnalysisModal(index: number | undefined) {
    this.modalService.openQualitativeAnalysisModal(index);
  }

  deleteDetailOfReport(index: number) {
    this.ivrReportVersion.ivrReport.qualitativeAnalysis =
      this.ivrReportVersion.ivrReport.qualitativeAnalysis.filter(
        (qualitativeAnalysis) =>
          qualitativeAnalysis !==
          this.ivrReportVersion.ivrReport.qualitativeAnalysis[index]
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
