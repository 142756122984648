import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormState } from 'src/app/models/enums/formState.enum';
import { AsYouType } from 'libphonenumber-js';
import { Utility } from 'src/app/shared/utilities/utility';
import { IvrDatasourceService } from 'src/app/shared/data/ivr-datasource.service';
import { Ivr } from 'src/app/models/ivr.model';
import { IvrService } from 'src/app/services/ivr.service';
import { ImprovementRequest } from 'src/app/models/improvementRequest.model';

@Component({
  selector: 'rmi-improvement-request',
  templateUrl: './improvement-request.component.html',
  styleUrls: ['./improvement-request.component.scss'],
})
export class ImprovementRequestComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();
  formState = FormState;
  public state$ = new BehaviorSubject(FormState.READY_FOR_SUBMISSION);
  formSentImageLoaded = false;
  private ivrNumber: string = '';
  private ivrId: string = '';

  form = this.fb.group({
    phoneNumber: ['', [Utility.phoneNumberValidator()]],
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    originalInfo: [false],
  });
  errorMessage?: string;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private ivrService: IvrService,
    private ivrDataSource: IvrDatasourceService
  ) {}

  ngOnInit(): void {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => {
        this.ivrNumber = ivr.telephoneNumber;
        this.ivrId = ivr.ivrId;
      });
  }
  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();
      this.state$.next(FormState.LOADING);
      const plusSignOmitted = this.form.controls['phoneNumber'].value.slice(1);
      const whitespaceOmitted = plusSignOmitted.replace(/\s+/g, '');
      const data: ImprovementRequest = {
        ivrPhone: whitespaceOmitted,
        originalInfo: this.form.controls['originalInfo'].value,
        ivrId: this.ivrId,
        ...(this.form.controls['originalInfo'].value === false && {
          name: this.form.controls['name'].value,
          companyEmail: this.form.controls['email'].value,
        }),
      };
      this.ivrService
        .improvementRequest(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          complete: () => {
            this.state$.next(FormState.SUBMITTED);
            if (this.form.controls['originalInfo'].value === true) {
              this.form.controls['phoneNumber'].enable();
              this.form.controls['originalInfo'].enable();
            } else {
              this.form.enable();
            }
          },
          error: ({ error }) => {
            if (typeof error === 'string')
              this.errorMessage = error
            this.state$.next(FormState.ERROR);
            if (this.form.controls['originalInfo'].value === true) {
              this.form.controls['phoneNumber'].enable();
              this.form.controls['originalInfo'].enable();
            } else {
              this.form.enable();
            }
          },
        });
    }
  }

  formatPhoneNumberInput() {
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res === 'INVALID') {
        this.form.controls['phoneNumber'].markAllAsTouched();
      }
    });
    // check if the default part of the currentInput is invalid
    if (this.form.controls['phoneNumber'].value.substring(0, 1) !== '+') {
      // if it is then reset the input value to the default one
      this.form.controls['phoneNumber'].setValue('+');
    }
    try {
      this.form.controls['phoneNumber'].setValue(this.getInputNumberFormatted);
    } catch (error) {}
  }

  get getInputNumberFormatted(): string {
    const asYouType = new AsYouType();
    return asYouType.input(this.form.controls['phoneNumber'].value);
  }

  onImageLoad() {
    this.formSentImageLoaded = true;
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  closeModal() {
    this.activeModal.close();
  }

  checkboxToggled() {
    if (this.form.controls['originalInfo'].value === true) {
      this.form.controls['email'].patchValue('************');
      this.form.controls['name'].patchValue('************');
      this.form.controls['phoneNumber'].patchValue('+' + this.ivrNumber);
      this.form.controls['email'].disable();
      this.form.controls['name'].disable();
      this.form.controls['email'].setValidators([
        Validators.required,
        Validators.email,
      ]);
      this.form.controls['name'].setValidators([Validators.required]);
      this.formatPhoneNumberInput();
    } else {
      this.form.controls['email'].enable();
      this.form.controls['name'].enable();
      this.form.controls['email'].patchValue('');
      this.form.controls['name'].patchValue('');
      this.form.controls['email'].clearValidators();
      this.form.controls['name'].clearValidators();
    }
    this.form.controls['email'].updateValueAndValidity();
    this.form.controls['name'].updateValueAndValidity();
  }

  onInput(formControlName: string): void {
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res === 'INVALID') {
        this.form.controls[formControlName].markAllAsTouched();
      }
    });
  }

  onBlur(): void {
    this.form.markAsUntouched();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
