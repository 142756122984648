// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { StageName } from 'src/app/models/enums/stageName';

export const environment = {
  adminFlags: ['FIND_HIDDEN_MENUS_FLAG'],
  stage: StageName.Development,
  showPhoneNumber: false,
  website: 'https://dev.roastmyivr.com',
  apiUrl: 'https://api.dev.roastmyivr.com/v1',
  appApiUrl: 'https://api.dev.roastmyivr.com/rmi/v1',
  adminPanelUrl: 'https://admin.dev.roastmyivr.com',
  DefaultLocation: 'AU',
  cookiesExpireInYear: 10,
  ipGeoLocationUrl:
    'https://api.ipgeolocation.io/ipgeo?apiKey=784a7ad0edd74e8f9614cad27d1afc65',
  s3PublicUrl: 'https://rmi-public-assets.s3.ap-southeast-2.amazonaws.com',
  allowCookie: 'allow-cookie',
  fullStoryOrgId: '12WRD3',
  x_api_key: 'oNu6QwzTsC1DtBncfwVK847J7zPLHJ6z1PQNldvk',
  authCookieName: StageName.Development + '-ivr-auth',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
