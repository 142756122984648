import { IvrDatasourceService } from '../../../data/ivr-datasource.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Ivr } from 'src/app/models/ivr.model';

@Component({
  selector: 'rmi-report-editor-notes',
  templateUrl: './report-editor-notes.component.html',
  styleUrls: ['./report-editor-notes.component.scss'],
})
export class ReportEditorNotesComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject<void>();
  // @ts-ignore
  ivr: Ivr;

  // @ts-ignore
  form: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private ivrDataSource: IvrDatasourceService
  ) {}

  ngOnInit() {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => {
        this.form = this.fb.group({
          extraInformation: [ivr.ivrReportVersion!.ivrReport.extraInformation],
        });
        this.ivr = ivr;
      });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();

      const data: string = this.form.controls['extraInformation'].value;
      this.ivr.ivrReportVersion!.ivrReport.extraInformation = data;
      this.ivrDataSource.ivr$.next(this.ivr);
      this.closeModal();
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onInput(): void {
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res === 'INVALID') {
        this.form.markAllAsTouched();
      }
    });
  }

  onBlur(): void {
    this.form.markAsUntouched();
  }

  module = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button

      ['link'], // link and image, video
    ],
  };
}
