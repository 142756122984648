import * as FullStory from '@fullstory/browser';
import { StageName } from 'src/app/models/enums/stageName';
import { environment } from 'src/environments/environment';

export enum TrackerEvent {
  // Event-pages-changed
  ROUTE_PAGE_LANDING = 'ROUTE_PAGE_LANDING',
  ROUTE_PAGE_PRIVACY_POLICY = 'ROUTE_PAGE_PRIVACY_POLICY',
  ROUTE_PAGE_TERMS_OF_SERVICE = 'ROUTE_PAGE_TERMS_OF_SERVICE',
  ROUTE_PAGE_REPORT = 'ROUTE_PAGE_REPORT',
  ROUTE_PAGE_SUBSCRIPTION = 'ROUTE_PAGE_SUBSCRIPTION',

  // Event-actions
  ACTION_RESTORE_SESSION = 'ACTION_RESTORE_SESSION',
}

export function trackerInit(): void {
  FullStory.init({
    orgId: environment.fullStoryOrgId,
    devMode:
      environment.stage === StageName.Local ||
      environment.stage === StageName.Development,
  });
}

export function trackerIdentityWithUserProfileIfExists(): boolean {
  // let userProfile;
  // try {
  //   const userProfileString = localStorage.getItem('user');
  //   userProfile = JSON.parse(userProfileString);
  // } catch (e) {}

  // if (userProfile) {
  //   try {
  //     FullStory.identify(sha256(userProfile.sub).toString(), {
  //       // Creates non-readable records for FS.
  //       // displayName: sha256(userProfile.name || userProfile.nickname || userProfile.email).toString(),
  //     });

  //     trackerUserVars({
  //       iss: userProfile.iss,
  //       host: document.location.host,
  //     });
  //     return true;
  //   } catch (err) {
  //     // error - fallback to anonymity
  //     console.error(err);
  //   }
  // }
  // else: no profile - fallback to anonymity
  trackerAnonymize();
  return false;
}

export function trackerAnonymize(): void {
  try {
    FullStory.anonymize();
    trackerUserVars({
      host: document.location.host,
    });
  } catch (err) {
    console.error(err);
  }
}

/**
 * Extend the user with tracking data
 */
export function trackerUserVars(opts?: { [key: string]: any }): void {
  try {
    FullStory.setUserVars(
      !opts
        ? {}
        : {
            ...normalizeVars(opts),
          }
    );
  } catch (err) {
    // error - fallback to anonymity
    console.error(err);
  }
}

export function trackerEvent(
  event: TrackerEvent,
  opts?: { [key: string]: any }
): void {
  try {
    FullStory.event(
      event,
      !opts
        ? {}
        : {
            ...normalizeVars(opts),
          }
    );
  } catch (err) {
    // error - fallback to anonymity
    console.error(err);
    trackerAnonymize();
  }
}

function normalizeVars(opts: { [key: string]: any }): any {
  try {
    return Object.keys(opts).reduce((result, key) => {
      const value = opts[key];
      let varKey: string | undefined;
      let varValue: any | undefined;
      switch (typeof value) {
        case 'object':
          if (value === null) {
            varKey = `${key}_str`;
            varValue = '<null>';
          } else if (Array.isArray(value) && value.length) {
            const firstValueTypeOf = getSafeArrayTypeOf(value[0]);
            if (
              value.every((v) => getSafeArrayTypeOf(v) === firstValueTypeOf)
            ) {
              // all items are of same type - it's correct value
              switch (firstValueTypeOf) {
                case 'undefined':
                  varKey = `${key}_strs`;
                  varValue = value.map((_) => '<undefined>');
                  break;

                case 'boolean':
                  varKey = `${key}_bools`;
                  varValue = Array.prototype.slice.apply(value);
                  break;

                case 'string':
                  varKey = `${key}_strs`;
                  varValue = value.map((val) => val.toString());
                  break;

                case 'number':
                  varKey = `${key}_reals`;
                  varValue = value.map((val) => Number(val));
                  break;

                case 'object':
                  varKey = `${key}_strs`;
                  varValue = value.map((_) => '<null>');
                  break;
              }
            }
          }
          break;
        case 'boolean':
          varKey = `${key}_bool`;
          varValue = value;
          break;

        case 'bigint':
        case 'number':
          varKey = `${key}_real`;
          varValue = Number(value);
          break;

        case 'string':
        case 'symbol':
          varKey = `${key}_str`;
          varValue = value.toString();
          break;

        case 'undefined':
          varKey = `${key}_str`;
          varValue = '<undefined>';
          break;

        case 'function':
        default:
        // DO NOT SET varKey
      }
      if (varKey) {
        result[varKey] = varValue;
      }
      return result;
    }, {} as { [key: string]: any });
  } catch (err) {
    // error - fallback to anonymity
    console.error(err);
    return {};
  }

  function getSafeArrayTypeOf(val: any): string | undefined {
    switch (typeof val) {
      case 'undefined':
        return 'undefined';

      case 'boolean':
        return 'boolean';

      case 'symbol':
      case 'string':
        return 'string';

      case 'number':
      case 'bigint':
        return 'number';

      case 'object':
        return val === null ? 'object' : undefined;

      case 'function':
      default:
        return undefined;
    }
  }
}
