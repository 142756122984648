<div class="custom-container bg-white header py-0">
  <div class="d-flex flex-column">
    <div class="header__divider-line w-100 d-flex d-xl-none"></div>
    <!-- size > xl -->
    <div class="d-none d-xl-flex">
      <div
        class="d-flex align-items-center justify-content-between py-4 px-32px rounded-3 mb-4 w-100"
        [ngStyle]="{
          'background-color':
            ivr.ivrReportVersion!.ivrReport!.overallResult.colorWithOpacity
        }"
      >
        <div class="d-flex align-items-center">
          <div class="d-flex">
            <div
              class="header__short-detail d-flex flex-column align-items-center align-items-md-start mt-4 mt-xl-0"
            >
              <h2
                class="m-0"
                [innerHTML]="ivr.telephoneNumber | formatPhoneNumber"
              ></h2>
              <h5 class="mt-1">
                {{ getFullDateAndTime }}
              </h5>
            </div>
          </div>
          <div
            class="header__cards d-flex align-items-center flex-xl-row flex-column mt-md-0 mt-4"
          >
            <div class="d-flex header__card ms-64px">
              <rmi-score
                [reportStatus]="ivr.ivrReportVersion!.ivrReport!.overallResult"
                [large]="true"
              ></rmi-score>
              <div
                class="d-flex flex-column align-items-center align-items-sm-start ms-sm-3"
              >
                <span i18n="@@ivr.roastingOverallScore"
                  >Roasting Overall Score</span
                >
                <rmi-report-status
                  [reportStatus]="
                    ivr.ivrReportVersion!.ivrReport!.overallResult
                  "
                ></rmi-report-status>
              </div>
              <rmi-edit-report-button-by-admin
                (openRelatedEditModal)="openEditOverallScoreModal()"
              >
              </rmi-edit-report-button-by-admin>
            </div>
          </div>
        </div>
        <Button
          i18n="@@common.improveMyIvr"
          class="btn primary"
          (click)="openImprovementRequestModal()"
          >Improve My IVR!</Button
        >
      </div>
    </div>

    <!--  size < xl -->
    <div
      *ngIf="ivr && ivr.processStatus === ivrProcessStatus.Completed"
      class="row m-0 d-xl-none align-items-start justify-content-center py-4"
    >
      <div class="col-md-6 col-12 p-0">
        <div
          class="header__title d-xl-none d-md-inline-block d-flex flex-column-reverse flex-md-row align-items-center"
        >
          <h2 i18n="@@ivr.analysisReport" class="mb-0 mt-1 mt-md-0">
            IVR analysis report
          </h2>
          <span i18n="@@common.free">FREE</span>
        </div>
        <div
          class="header__short-detail d-md-flex d-none flex-column align-items-center align-items-md-start mt-4 mt-xl-0"
        >
          <h2
            class="m-0"
            [innerHTML]="ivr.telephoneNumber | formatPhoneNumber"
          ></h2>
          <h5 class="mt-1">
            {{ getFullDateAndTime }}
          </h5>
        </div>
      </div>
      <div class="col-md-6 col-12 mt-md-0 mt-4 p-0">
        <div
          class="d-flex align-items-lg-start align-items-center flex-column p-4 rounded-3"
          [ngStyle]="{
            'background-color':
              ivr.ivrReportVersion!.ivrReport!.overallResult.colorWithOpacity
          }"
        >
          <div
            class="header__cards d-flex align-items-center flex-xl-row flex-column w-100"
          >
            <!-- size > lg -->
            <div class="d-lg-flex d-none header__card flex-row">
              <rmi-score
                [reportStatus]="ivr.ivrReportVersion!.ivrReport!.overallResult"
                [large]="true"
              ></rmi-score>
              <div
                class="d-flex flex-column align-items-center align-items-sm-start ms-sm-3"
              >
                <span i18n="@@ivr.roastingOverallScore"
                  >Roasting Overall Score</span
                >
                <rmi-report-status
                  [reportStatus]="
                    ivr.ivrReportVersion!.ivrReport!.overallResult
                  "
                ></rmi-report-status>
              </div>
            </div>

            <!-- size < lg -->
            <div
              class="d-lg-none d-flex header__card flex-column align-items-center w-100"
            >
              <div
                class="header__short-detail d-md-none d-flex flex-column align-items-center align-items-md-start"
              >
                <h2
                  class="m-0"
                  [innerHTML]="ivr.telephoneNumber | formatPhoneNumber"
                ></h2>
                <h5 class="mt-1">
                  {{ getFullDateAndTime }}
                </h5>
              </div>
              <div
                class="header__divider-line w-100 d-flex bg-white my-4 d-md-none"
              ></div>
              <span i18n="@@ivr.roastingOverallScore"
                >Roasting Overall Score</span
              >
              <rmi-score
                class="mt-12px"
                [reportStatus]="ivr.ivrReportVersion!.ivrReport!.overallResult"
                [large]="true"
              ></rmi-score>
              <rmi-report-status
                class="mt-12px"
                [reportStatus]="ivr.ivrReportVersion!.ivrReport!.overallResult"
              ></rmi-report-status>
            </div>
          </div>
          <Button
            i18n="@@common.improveMyIvr"
            class="btn primary w-100 text-center mt-4 d-none d-sm-block"
            (click)="openImprovementRequestModal()"
            >Improve My IVR!</Button
          >
        </div>
      </div>
    </div>
  </div>
</div>
