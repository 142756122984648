import { IvrDatasourceService } from '../../../data/ivr-datasource.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  getReportStatusColorAsKeyValue,
  getReportStatusColorWithOpacityValue,
  getReportStatusNameAsKeyValue,
  getReportStatusStarsAsKeyValue,
  ReportStatus,
  ReportStatusColor,
  ReportStatusName,
} from 'src/app/models/report.model';
import { Ivr } from 'src/app/models/ivr.model';

@Component({
  selector: 'rmi-edit-sound-quality-noise',
  templateUrl: './edit-sound-quality-noise.component.html',
  styleUrls: ['./edit-sound-quality-noise.component.scss'],
})
export class EditSoundQualityNoiseComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject<void>();
  showScoreAndColor = false;
  get reportStatusNameOptions() {
    return getReportStatusNameAsKeyValue(true);
  }
  get reportStatusColorOptions() {
    return getReportStatusColorAsKeyValue();
  }
  get reportStatusStarOptions() {
    return getReportStatusStarsAsKeyValue();
  }

  // @ts-ignore
  form: FormGroup;
  errorMessage: string = '';
  // @ts-ignore
  ivr: Ivr;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private ivrDataSource: IvrDatasourceService
  ) {}
  ngOnInit(): void {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => {
        this.form = this.fb.group({
          statusText: [
            ivr.ivrReportVersion!.ivrReport.audioNoise.statusText,
            [Validators.required],
          ],
          color: [
            ivr.ivrReportVersion!.ivrReport.audioNoise.color,
            Validators.required,
          ],
          stars: [
            ivr.ivrReportVersion!.ivrReport.audioNoise.stars,
            [Validators.required],
          ],
          colorWithOpacity: [
            ivr.ivrReportVersion!.ivrReport.audioNoise.colorWithOpacity,
            [Validators.required],
          ],
        });
        this.onChangeStatus(
          ivr.ivrReportVersion!.ivrReport.audioNoise.statusText
        );
        this.ivr = ivr;
      });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();

      const data: ReportStatus = {
        color: this.form.controls['color'].value,
        colorWithOpacity: this.form.controls['colorWithOpacity'].value,
        stars: this.form.controls['stars'].value,
        statusText: this.form.controls['statusText'].value,
      };
      this.ivr.ivrReportVersion!.ivrReport.audioNoise = data;
      this.ivrDataSource.ivr$.next(this.ivr);
      this.closeModal();
    }
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onInput(): void {
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res === 'INVALID') {
        this.form.markAllAsTouched();
      }
    });
  }

  onBlur(): void {
    this.form.markAsUntouched();
  }

  onChangeStatus(status: String): void {
    if (status !== ReportStatusName.NotAvailable) {
      this.showScoreAndColor = true;
    } else {
      this.showScoreAndColor = false;
      this.setNotAvailableStatusForNoiseStatus();
    }
  }

  setNotAvailableStatusForNoiseStatus() {
    this.onChangeColor(ReportStatusColor.Red);
    this.form.controls['color'].setValue(ReportStatusColor.Red);
    this.form.controls['stars'].setValue(0);
  }

  onChangeColor(colorStatus: ReportStatusColor): void {
    this.form.controls['colorWithOpacity'].setValue(
      getReportStatusColorWithOpacityValue(colorStatus)
    );
  }

  identify(index:number, item:any){
    return item.key;
 }
}
