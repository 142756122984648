<rmi-ivr-analysis-report-header
  [showSkeleton]="ivrDataSource.loading$.value"
></rmi-ivr-analysis-report-header>

<div *ngIf="(ivrDataSource.loading$ | async) === false; else reportSkeleton">
  <rmi-report
    *ngIf="ivrPageState === ivrPageStateEnum.Completed; else reportState"
  ></rmi-report>
  <ng-template #reportState>
    <rmi-report-state></rmi-report-state>
  </ng-template>
</div>
<rmi-ivr-analysis-report-footer
  class="d-flex mb-56px mb-sm-0"
></rmi-ivr-analysis-report-footer>

<ng-template #reportSkeleton>
  <rmi-ivr-report-skeleton></rmi-ivr-report-skeleton>
</ng-template>
