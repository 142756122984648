import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ReportComponent } from './report.component';
import { DetailedAnalysisComponent } from './detailed-analysis/detailed-analysis.component';
import { DiscoveredMenuComponent } from './discovered-menu/discovered-menu.component';
import { HowAboutImprovementsComponent } from './how-about-improvements/how-about-improvements.component';
import { RecommendationsComponent } from './recommendations/recommendations.component';
import { TestAnotherIvrComponent } from './test-another-ivr/test-another-ivr.component';
import { OverallReportComponent } from './overall-report/overall-report.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { SharedModule } from 'src/app/shared/shared.module';
import { RouterModule } from '@angular/router';
import { NotesComponent } from './notes/notes.component';
import { MenuIvrTreeComponent } from './menu-ivr-tree/menu-ivr-tree.component';

@NgModule({
  declarations: [
    ReportComponent,
    DetailedAnalysisComponent,
    DiscoveredMenuComponent,
    RecommendationsComponent,
    HowAboutImprovementsComponent,
    TestAnotherIvrComponent,
    MenuIvrTreeComponent,
    OverallReportComponent,
    NotesComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule,
  ],
  providers: [DatePipe],

  exports: [ReportComponent],
})
export class ReportModule {}
