<div class="custom-container bg-white header py-0">
  <div class="d-flex flex-column">
    <div class="header__divider-line w-100 d-flex d-xl-none"></div>

    <!-- size > xl -->
    <div class="d-none d-xl-flex">
      <div class="middle-skeleton">
        <div class="line-skeleton d-flex flex-column">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 32,
              'width.px': 236,
              'border-radius.px': 8,
              'margin-bottom.px': 6,
              'background-color': 'rgba(0, 0, 0, 0.07)'
            }"
            appearance="line"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 24,
              'width.px': 282,
              'border-radius.px': 8,
              'margin-bottom.px': 0,
              'background-color': 'rgba(0, 0, 0, 0.07)'
            }"
            appearance="line"
          ></ngx-skeleton-loader>
        </div>
        <div class="circle-skeleton">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 68,
              'width.px': 152,
              'border-radius.px': 70,
              'margin-bottom.px': 0,
              'background-color': 'rgba(0, 0, 0, 0.07)'
            }"
            appearance="circle"
          ></ngx-skeleton-loader>
        </div>
        <div class="small-line-skeletons d-flex flex-column">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 24,
              'width.px': 193,
              'border-radius.px': 8,
              'margin-bottom.px': 6,
              'background-color': 'rgba(0, 0, 0, 0.07)'
            }"
            appearance="line"
          ></ngx-skeleton-loader>
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 24,
              'width.px': 165,
              'border-radius.px': 8,
              'margin-bottom.px': 0,
              'background-color': 'rgba(0, 0, 0, 0.07)'
            }"
            appearance="line"
          ></ngx-skeleton-loader>
        </div>
        <div class="line-skeleton">
          <ngx-skeleton-loader
            [theme]="{
              'height.px': 68,
              'width.px': 204,
              'border-radius.px': 8,
              'margin-bottom.px': 0,
              'background-color': 'rgba(0, 0, 0, 0.07)'
            }"
            appearance="line"
          ></ngx-skeleton-loader>
        </div>
      </div>
    </div>

    <!--  size < xl -->
    <div
      class="row m-0 d-xl-none align-items-start justify-content-center py-4"
    >
      <div class="col-md-6 col-12 p-0">
        <div
          class="header__title d-xl-none d-md-inline-block d-flex flex-column-reverse flex-md-row align-items-center"
        >
          <h2 i18n="@@ivr.analysisReport" class="mb-0 mt-1 mt-md-0">
            IVR analysis report
          </h2>
          <span i18n="@@common.free">FREE</span>
        </div>
        <div class="left-side-skeleton">
          <div class="line-skeleton w-100">
            <ngx-skeleton-loader
              [theme]="{
                'height.px': 32,
                'width.%': 42,
                'border-radius.px': 8,
                display: 'flex',
                'background-color': 'rgba(0, 0, 0, 0.07)'
              }"
              appearance="line"
            ></ngx-skeleton-loader>
            <ngx-skeleton-loader
              [theme]="{
                'height.px': 24,
                'width.%': 51,
                'border-radius.px': 8,
                display: 'flex',
                'background-color': 'rgba(0, 0, 0, 0.07)'
              }"
              appearance="line"
            ></ngx-skeleton-loader>
          </div>
        </div>
        <div class="d-md-none">
          <div class="p-32px">
            <div class="skeleton-content middle-line">
              <ngx-skeleton-loader
                [theme]="{
                  'height.px': 32,
                  'width.%': 47,
                  'border-radius.px': 8,
                  'margin-bottom.px': 0,
                  'background-color': 'rgba(0, 0, 0, 0.07)'
                }"
                appearance="line"
              ></ngx-skeleton-loader>
            </div>
            <div class="skeleton-content">
              <ngx-skeleton-loader
                [theme]="{
                  'height.px': 24,
                  'width.%': 56,
                  'border-radius.px': 8,
                  'margin-bottom.px': 0,
                  'background-color': 'rgba(0, 0, 0, 0.07)'
                }"
                appearance="line"
              ></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-12 mt-md-0 mt-4 p-0">
        <div class="right-side-skeleton">
          <div class="d-flex">
            <div>
              <ngx-skeleton-loader
                [theme]="{
                  'height.px': 68,
                  'width.px': 152,
                  'margin-top.px': 0,
                  'margin-right.px': 24,
                  'border-radius.px': 70,
                  'background-color': 'rgba(0, 0, 0, 0.07)'
                }"
                appearance="line"
              ></ngx-skeleton-loader>
            </div>
            <div class="w-100">
              <ngx-skeleton-loader
                [theme]="{
                  'height.px': 24,
                  'width.%': 100,
                  'border-radius.px': 8,
                  'margin-bottom.px': 6,
                  display: 'flex',
                  'background-color': 'rgba(0, 0, 0, 0.07)'
                }"
                appearance="line"
              ></ngx-skeleton-loader>
              <ngx-skeleton-loader
                [theme]="{
                  'height.px': 24,
                  'width.%': 60,
                  'border-radius.px': 8,
                  'margin-bottom.px': 0,
                  display: 'flex',
                  'background-color': 'rgba(0, 0, 0, 0.07)'
                }"
                appearance="line"
              ></ngx-skeleton-loader>
            </div>
          </div>
          <div>
            <ngx-skeleton-loader
              [theme]="{
                'height.px': 56,
                'border-radius.px': 8,
                'margin-bottom.px': 0,
                'background-color': 'rgba(0, 0, 0, 0.07)'
              }"
              appearance="line"
            ></ngx-skeleton-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="custom-container py-32px pt-md-40px bg-gray w-100 d-flex justify-content-center report-middle-parent-container"
>
  <div class="d-flex flex-column flex-lg-row justify-content-xxl-between w-100">
    <div
      class="col-lg-6 col-12 pe-0 pe-lg-2 pe-xxl-unset report-middle-container"
    >
      <div class="d-flex w-100 flex-column">
        <h2 i18n="@@ivr.discoveredMenu" class="title mb-4">Discovered Menu</h2>
        <ul class="tree p-md-32px p-3 m-0 rounded-top-3">
          <div>
            <div>
              <ngx-skeleton-loader
                [theme]="{
                  'height.px': 46,
                  'border-radius.px': 8,
                  'background-color': 'rgba(0, 0, 0, 0.07)'
                }"
                appearance="line"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                [theme]="{
                  'height.px': 124,
                  'border-radius.px': 8,
                  'background-color': 'rgba(0, 0, 0, 0.07)'
                }"
                appearance="line"
              ></ngx-skeleton-loader>
            </div>
            <div>
              <ngx-skeleton-loader
                count="4"
                [theme]="{
                  'height.px': 36,
                  'width.px': 88,
                  'border-radius.px': 8,
                  display: 'block',
                  'background-color': 'rgba(0, 0, 0, 0.07)'
                }"
                appearance="line"
              ></ngx-skeleton-loader>
            </div>
          </div>
        </ul>
        <div
          class="rounded-bottom-3 mt-2px report-problem d-flex justify-content-center align-items-center"
        >
          <div class="mt-3">
            <div>
              <ngx-skeleton-loader
                [theme]="{
                  'height.px': 24,
                  'width.px': 181,
                  'border-radius.px': 8,
                  'margin-bottom.px': 11,
                  'background-color': 'rgba(0, 0, 0, 0.07)'
                }"
                appearance="line"
              ></ngx-skeleton-loader>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-6 col-12 ps-0 ps-lg-2 ps-xxl-unset mt-32px mt-sm-40px mt-lg-0 report-middle-container"
    >
      <div class="d-flex w-100 flex-column">
        <h2 i18n="@@ivr.detailedAnalysis" class="title mb-4">
          Detailed analysis
        </h2>
        <div class="position-relative">
          <div
            class="d-flex flex-column w-100 bg-white p-md-32px p-3 mt-2px rounded-corner"
          >
            <div class="d-flex justify-content-between top-bar-skeleton">
              <div class="detailed-analysis-line-skeleton w-100">
                <ngx-skeleton-loader
                  [theme]="{
                    'height.px': 24,
                    'width.%': 51,
                    'border-radius.px': 8,
                    'margin-bottom.px': 6,
                    display: 'flex',
                    'background-color': 'rgba(0, 0, 0, 0.07)'
                  }"
                  appearance="line"
                ></ngx-skeleton-loader>
              </div>
              <div>
                <ngx-skeleton-loader
                  [theme]="{
                    'height.px': 24,
                    'width.px': 34,
                    'border-radius.px': 8,
                    'margin-bottom.px': 0,
                    display: 'flex',
                    'background-color': 'rgba(0, 0, 0, 0.07)'
                  }"
                  appearance="line"
                ></ngx-skeleton-loader>
              </div>
            </div>
            <div class="dividing-line dividing-margin"></div>
            <div>
              <div class="detailed-analysis-line-skeleton">
                <ngx-skeleton-loader
                  [theme]="{
                    'height.px': 46,
                    'border-radius.px': 8,
                    'margin-bottom.px': 27,
                    'background-color': 'rgba(0, 0, 0, 0.07)'
                  }"
                  appearance="line"
                ></ngx-skeleton-loader>
              </div>

              <div class="d-flex">
                <div class="detailed-analysis-line-skeleton">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 100,
                      'width.px': 100,
                      'margin-top.px': 0,
                      'margin-right.px': 24,
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="circle"
                  ></ngx-skeleton-loader>
                </div>
                <div class="w-100">
                  <ngx-skeleton-loader
                    count="2"
                    [theme]="{
                      'height.px': 24,
                      'width.%': 100,
                      'border-radius.px': 8,
                      'margin-bottom.px': 6,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.%': 60,
                      'border-radius.px': 8,
                      'margin-bottom.px': 0,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column w-100 bg-white p-md-32px p-3 mt-2px">
            <div>
              <div class="detailed-analysis-line-skeleton">
                <ngx-skeleton-loader
                  [theme]="{
                    'height.px': 46,
                    'border-radius.px': 8,
                    'margin-bottom.px': 27,
                    'background-color': 'rgba(0, 0, 0, 0.07)'
                  }"
                  appearance="line"
                ></ngx-skeleton-loader>
              </div>

              <div class="d-flex">
                <div class="detailed-analysis-line-skeleton">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 100,
                      'width.px': 100,
                      'margin-top.px': 0,
                      'margin-right.px': 24,
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="circle"
                  ></ngx-skeleton-loader>
                </div>
                <div class="w-100">
                  <ngx-skeleton-loader
                    count="2"
                    [theme]="{
                      'height.px': 24,
                      'width.%': 100,
                      'border-radius.px': 8,
                      'margin-bottom.px': 6,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.%': 60,
                      'border-radius.px': 8,
                      'margin-bottom.px': 0,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex flex-column w-100 bg-white p-md-32px p-3 mt-2px">
            <div>
              <div class="detailed-analysis-line-skeleton">
                <ngx-skeleton-loader
                  [theme]="{
                    'height.px': 24,
                    'width.px': 123,
                    'border-radius.px': 8,
                    'margin-bottom.px': 27,
                    'background-color': 'rgba(0, 0, 0, 0.07)'
                  }"
                  appearance="line"
                ></ngx-skeleton-loader>
              </div>

              <div class="d-flex justify-content-between innerSkeleton">
                <div class="detailed-analysis-line-skeleton">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 30,
                      'width.px': 59,
                      'border-radius.px': 30,
                      'margin-bottom.px': 0,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                </div>
                <div class="d-flex w-100 justify-content-between line-margin">
                  <div class="detailed-analysis-line-skeleton">
                    <ngx-skeleton-loader
                      [theme]="{
                        'height.px': 24,
                        'width.px': 48,
                        'border-radius.px': 8,
                        'margin-bottom.px': 0,
                        display: 'flex',
                        'background-color': 'rgba(0, 0, 0, 0.07)'
                      }"
                      appearance="line"
                    ></ngx-skeleton-loader>
                  </div>

                  <div class="detailed-analysis-line-skeleton">
                    <ngx-skeleton-loader
                      [theme]="{
                        'height.px': 24,
                        'width.px': 228,
                        'border-radius.px': 8,
                        'margin-bottom.px': 0,
                        display: 'flex',
                        'background-color': 'rgba(0, 0, 0, 0.07)'
                      }"
                      appearance="line"
                    ></ngx-skeleton-loader>
                  </div>
                </div>
              </div>

              <div class="dividing-line"></div>
              <div class="d-flex justify-content-between innerSkeleton">
                <div class="detailed-analysis-line-skeleton">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 30,
                      'width.px': 59,
                      'border-radius.px': 30,
                      'margin-bottom.px': 0,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                </div>
                <div class="d-flex w-100 justify-content-between line-margin">
                  <div class="detailed-analysis-line-skeleton">
                    <ngx-skeleton-loader
                      [theme]="{
                        'height.px': 24,
                        'width.px': 48,
                        'border-radius.px': 8,
                        'margin-bottom.px': 0,
                        display: 'flex',
                        'background-color': 'rgba(0, 0, 0, 0.07)'
                      }"
                      appearance="line"
                    ></ngx-skeleton-loader>
                  </div>

                  <div class="detailed-analysis-line-skeleton">
                    <ngx-skeleton-loader
                      [theme]="{
                        'height.px': 24,
                        'width.px': 75,
                        'border-radius.px': 8,
                        'margin-bottom.px': 0,
                        display: 'flex',
                        'background-color': 'rgba(0, 0, 0, 0.07)'
                      }"
                      appearance="line"
                    ></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
              <div class="dividing-line"></div>
              <div class="d-flex justify-content-between innerSkeleton">
                <div class="detailed-analysis-line-skeleton">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 30,
                      'width.px': 59,
                      'border-radius.px': 30,
                      'margin-bottom.px': 0,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                </div>
                <div class="d-flex w-100 justify-content-between line-margin">
                  <div class="detailed-analysis-line-skeleton">
                    <ngx-skeleton-loader
                      [theme]="{
                        'height.px': 24,
                        'width.px': 48,
                        'border-radius.px': 8,
                        'margin-bottom.px': 0,
                        display: 'flex',
                        'background-color': 'rgba(0, 0, 0, 0.07)'
                      }"
                      appearance="line"
                    ></ngx-skeleton-loader>
                  </div>

                  <div class="detailed-analysis-line-skeleton">
                    <ngx-skeleton-loader
                      [theme]="{
                        'height.px': 24,
                        'width.px': 153,
                        'border-radius.px': 8,
                        'margin-bottom.px': 0,
                        display: 'flex',
                        'background-color': 'rgba(0, 0, 0, 0.07)'
                      }"
                      appearance="line"
                    ></ngx-skeleton-loader>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="d-flex flex-column w-100 bg-white p-md-32px p-3 mt-2px rounded-bottom-3"
          >
            <div>
              <div class="detailed-analysis-line-skeleton">
                <ngx-skeleton-loader
                  [theme]="{
                    'height.px': 24,
                    'width.px': 170,
                    'border-radius.px': 8,
                    'margin-bottom.px': 27,
                    'background-color': 'rgba(0, 0, 0, 0.07)'
                  }"
                  appearance="line"
                ></ngx-skeleton-loader>
              </div>

              <div class="d-flex">
                <div class="detailed-analysis-line-skeleton">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.px': 24,
                      'margin-top.px': 0,
                      'margin-right.px': 24,
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="circle"
                  ></ngx-skeleton-loader>
                </div>
                <div class="w-100">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.%': 100,
                      'border-radius.px': 8,
                      'margin-bottom.px': 6,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.%': 41,
                      'border-radius.px': 8,
                      'margin-bottom.px': 0,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                </div>
              </div>

              <div class="dividing-line"></div>

              <div class="d-flex">
                <div class="detailed-analysis-line-skeleton">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.px': 24,
                      'margin-top.px': 0,
                      'margin-right.px': 24,
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="circle"
                  ></ngx-skeleton-loader>
                </div>
                <div class="w-100">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.%': 100,
                      'border-radius.px': 8,
                      'margin-bottom.px': 6,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                </div>
              </div>

              <div class="dividing-line"></div>

              <div class="d-flex">
                <div class="detailed-analysis-line-skeleton">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.px': 24,
                      'margin-top.px': 0,
                      'margin-right.px': 24,
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="circle"
                  ></ngx-skeleton-loader>
                </div>
                <div class="w-100">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.%': 100,
                      'border-radius.px': 8,
                      'margin-bottom.px': 6,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.%': 12,
                      'border-radius.px': 8,
                      'margin-bottom.px': 0,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                </div>
              </div>

              <div class="dividing-line"></div>
              <div class="d-flex">
                <div class="detailed-analysis-line-skeleton">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.px': 24,
                      'margin-top.px': 0,
                      'margin-right.px': 24,
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="circle"
                  ></ngx-skeleton-loader>
                </div>
                <div class="w-100">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.%': 100,
                      'border-radius.px': 8,
                      'margin-bottom.px': 6,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.%': 41,
                      'border-radius.px': 8,
                      'margin-bottom.px': 0,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                </div>
              </div>

              <div class="dividing-line"></div>

              <div class="d-flex">
                <div class="detailed-analysis-line-skeleton">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.px': 24,
                      'margin-top.px': 0,
                      'margin-right.px': 24,
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="circle"
                  ></ngx-skeleton-loader>
                </div>
                <div class="w-100">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.%': 100,
                      'border-radius.px': 8,
                      'margin-bottom.px': 6,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                </div>
              </div>
              <div class="dividing-line"></div>
              <div class="d-flex">
                <div class="detailed-analysis-line-skeleton">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.px': 24,
                      'margin-top.px': 0,
                      'margin-right.px': 24,
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="circle"
                  ></ngx-skeleton-loader>
                </div>
                <div class="w-100">
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.%': 100,
                      'border-radius.px': 8,
                      'margin-bottom.px': 6,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                  <ngx-skeleton-loader
                    [theme]="{
                      'height.px': 24,
                      'width.%': 12,
                      'border-radius.px': 8,
                      'margin-bottom.px': 0,
                      display: 'flex',
                      'background-color': 'rgba(0, 0, 0, 0.07)'
                    }"
                    appearance="line"
                  ></ngx-skeleton-loader>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- TODO: To be removed, if the parent skeleton is going to be used. -->
<!-- <ng-template #reportStateSkeleton>
  <div class="mt-3 skeleton w-100">
    <div>
      <ngx-skeleton-loader
        [theme]="{
          'height.px': 190,
          'width.px': 190,
          'margin-bottom.px': 40,
          'background-color': 'rgba(0, 0, 0, 0.07)'
        }"
        appearance="circle"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [theme]="{
          'height.px': 56,
          'width.%': 30,
          'border-radius.px': 8,
          'margin-bottom.px': 28,
          'background-color': 'rgba(0, 0, 0, 0.07)'
        }"
        appearance="line"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [theme]="{
          'height.px': 24,
          'width.%': 45,
          'border-radius.px': 8,
          'margin-bottom.px': 3,
          'background-color': 'rgba(0, 0, 0, 0.07)'
        }"
        appearance="line"
      ></ngx-skeleton-loader>
    </div>
    <div>
      <ngx-skeleton-loader
        [theme]="{
          'height.px': 24,
          'width.%': 40,
          'border-radius.px': 8,
          'margin-bottom.px': 11,
          'background-color': 'rgba(0, 0, 0, 0.07)'
        }"
        appearance="line"
      ></ngx-skeleton-loader>
    </div>
  </div>
</ng-template> -->
