import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormState } from 'src/app/models/enums/formState.enum';
import { Ivr } from 'src/app/models/ivr.model';
import { ReportProblem } from 'src/app/models/reportProblem.model';
import { IvrService } from 'src/app/services/ivr.service';
import { IvrDatasourceService } from 'src/app/shared/data/ivr-datasource.service';

@Component({
  selector: 'rmi-report-problem',
  templateUrl: './report-problem.component.html',
  styleUrls: ['./report-problem.component.scss'],
})
export class ReportProblemComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  formState = FormState;
  public state$ = new BehaviorSubject(FormState.READY_FOR_SUBMISSION);
  formSentImageLoaded = false;
  private ivrId: string = '';

  form = this.fb.group({
    message: ['', [Validators.required]],
    sendAnswerToOriginalEmail: [false],
  });
  errorMessage?: string;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private ivrService: IvrService,
    private ivrDataSource: IvrDatasourceService
  ) {}

  ngOnInit(): void {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => {
        this.ivrId = ivr.ivrId;
      });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();
      this.state$.next(FormState.LOADING);
      const data: ReportProblem = {
        message: this.form.controls['message'].value,
        ivrId: this.ivrId,
        sendAnswerToOriginalEmail:
          this.form.controls['sendAnswerToOriginalEmail'].value,
      };
      this.ivrService
        .reportProblem(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          complete: () => {
            this.state$.next(FormState.SUBMITTED);
            this.form.enable();
          },
          error: ({ error }) => {
            if (typeof error === 'string')
              this.errorMessage = error
            this.state$.next(FormState.ERROR);
            this.form.enable();
          },
        });
    }
  }

  onImageLoad() {
    this.formSentImageLoaded = true;
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  closeModal() {
    this.activeModal.close();
  }

  onInput(): void {
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res === 'INVALID') {
        this.form.markAllAsTouched();
      }
    });
  }

  onBlur(): void {
    this.form.markAsUntouched();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
