import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Ivr } from 'src/app/models/ivr.model';
import { QualitativeAnalysisStatus } from 'src/app/models/report.model';
import { IvrDatasourceService } from 'src/app/shared/data/ivr-datasource.service';
import { getOrderOptionsAsKeyValue } from 'src/app/models/report.model';

@Component({
  selector: 'rmi-edit-qualitative-analysis',
  templateUrl: './edit-qualitative-analysis.component.html',
  styleUrls: ['./edit-qualitative-analysis.component.scss'],
})
export class EditQualitativeAnalysisComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @Input() index: number | undefined;

  hasIssueChecked = false;

  // @ts-ignore
  form: FormGroup;
  // @ts-ignore
  ivr: Ivr;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private ivrDataSource: IvrDatasourceService
  ) { }

  get reportRecommendationsOrderOptions() {
    const reportQualitativeAnalysis = this.ivr.ivrReportVersion!.ivrReport.qualitativeAnalysis;
    if (this.index === undefined) {
      return getOrderOptionsAsKeyValue(reportQualitativeAnalysis.length + 1);
    } else {
      return getOrderOptionsAsKeyValue(reportQualitativeAnalysis.length);
    }
  }

  ngOnInit(): void {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => {
        if (this.index === undefined) {
          this.hasIssueChecked = false;
          this.form = this.fb.group({
            text: [, [Validators.required]],
            hasIssue: [false],
            description: [],
            numberOfIssues: [],
            analysisPriority: [],
          });
        } else {
          this.hasIssueChecked =
            ivr.ivrReportVersion!.ivrReport.qualitativeAnalysis[
              this.index
            ].hasIssue;
          this.form = this.fb.group({
            text: [
              ivr.ivrReportVersion!.ivrReport.qualitativeAnalysis[this.index]
                .text,
              [Validators.required],
            ],
            hasIssue: [
              ivr.ivrReportVersion!.ivrReport.qualitativeAnalysis[this.index]
                .hasIssue,
            ],
            description: [
              ivr.ivrReportVersion!.ivrReport.qualitativeAnalysis[this.index]
                .description,
            ],
            numberOfIssues: [
              ivr.ivrReportVersion!.ivrReport.qualitativeAnalysis[this.index]
                .numberOfIssues,
            ],
            analysisPriority: [
              this.index,
            ]
          });
        }
        this.ivr = ivr;
      });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();
      const qualatativeanalysis = this.ivr.ivrReportVersion!.ivrReport.qualitativeAnalysis;
      const priority = this.form.controls['analysisPriority'].value;
      const data: QualitativeAnalysisStatus = {
        hasIssue: this.form.controls['hasIssue'].value,
        text: this.form.controls['text'].value,
        description: this.form.controls['description'].value,
        numberOfIssues: this.form.controls['numberOfIssues'].value,
      };
      if (this.index === undefined) {
        qualatativeanalysis.push(data);
         this.shiftArrayItems(qualatativeanalysis, priority, qualatativeanalysis.length - 1);
      } else {
        qualatativeanalysis[this.index] = data;
        this.shiftArrayItems(qualatativeanalysis, priority, this.index);
      }

      this.ivrDataSource.ivr$.next(this.ivr);
      this.closeModal();
    }
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  closeModal() {
    this.activeModal.close();
  }

  onInput(): void {
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res === 'INVALID') {
        this.form.markAllAsTouched();
      }
    });
  }

  onBlur(): void {
    this.form.markAsUntouched();
  }

  onHasIssueChange(event: any): void {
    this.hasIssueChecked = this.form.controls['hasIssue'].value;
    if (!this.hasIssueChecked) {
      this.form.controls['numberOfIssues'].setValue(0);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  identify(index:number, item:any){
    return item.key;
  }

  shiftArrayItems(sourceArray: QualitativeAnalysisStatus[], to: number, from: number): QualitativeAnalysisStatus[] {
    return sourceArray.splice(to, 0, sourceArray.splice(from, 1)[0]);
  }
}
