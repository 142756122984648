import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IvrPageStateEnum } from 'src/app/models/enums/ivrPageState.enum';
import { Ivr } from 'src/app/models/ivr.model';
import { ModalService } from 'src/app/services/modal.service';
import { IvrDatasourceService } from 'src/app/shared/data/ivr-datasource.service';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'rmi-report-state',
  templateUrl: './report-state.component.html',
  styleUrls: ['./report-state.component.scss'],
})
export class ReportStateComponent implements OnInit {
  pageState = IvrPageStateEnum;
  private landingPage = environment.website;
  private readonly destroy$ = new Subject<void>();
  // @ts-ignore
  ivr: Ivr;
  // @ts-ignore
  ivrPageState: IvrPageStateEnum;

  constructor(
    public ivrDataSource: IvrDatasourceService,
    private modalService: ModalService,
    private activatedRoute: ActivatedRoute
  ) {}

  ngOnInit() {
    const reportProblemParam =
      this.activatedRoute.snapshot.queryParams['reportProblem'];
    this.ivrDataSource.ivrPageState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivrPageState: IvrPageStateEnum) => {
        this.ivrPageState = ivrPageState;
      });
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => {
        this.ivr = ivr;
        if (reportProblemParam) {
          this.openReportProblemModal();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  openReportProblemModal() {
    this.modalService.openReportProblemModal();
  }


  public showIvrRoastingForm() {
      window.open(this.landingPage + '#ivr', '_self')
  }

  openContactUsModal() {
    this.modalService.openContactUsModal();
  }
}
