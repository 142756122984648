import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Collection } from '../models/collection';
import { IvrProvider } from '../models/ivrProvider.model';

@Injectable({
  providedIn: 'root',
})
export class IvrProviderService {
  private BASE_API_URL = environment.apiUrl;
  constructor(private http: HttpClient) {}

  getIvrProviders(): Observable<Collection<IvrProvider>> {
    return this.http.get<Collection<IvrProvider>>(
      `${this.BASE_API_URL}/ivr-providers`
    );
  }

  addProviderToIvr(
    ivrId: string,
    providerName: string
  ): Observable<{ providerName: string }> {
    return this.http.patch<{ providerName: string }>(
      `${this.BASE_API_URL}/ivrs/${ivrId}/ivr-providers`,
      { providerName }
    );
  }
}
