<div
  *ngIf="reportStatus?.statusText !== 'Not Available'; else notAvailable"
  class="d-flex score-container align-items-center"
  [ngStyle]="{ 'background-color': reportStatus ? reportStatus.color : '' }"
  [ngClass]="{ 'px-12px ': large === false, 'px-20px ': large === true }"
>
  <img
    [height]="large ? 32 : 16"
    [width]="large ? 32 : 16"
    alt="star"
    src="/assets/star.svg"
  />
  <span
    class="text-white"
    [ngClass]="{
      'text-small ': large === false,
      'text-large ': large === true
    }"
    >{{ reportStatus?.stars }}</span
  >
</div>
<ng-template #notAvailable>
  <div #notAvailable>
    <div
    *ngIf="soundQualityRating"
    class="d-flex score-container align-items-center"
    [ngStyle]="{ 'background-color': reportStatus ? reportStatus.color : '' }"
    [ngClass]="{ 'px-12px ': large === false, 'px-20px ': large === true }"
  >
    <img
    [height]="32"
    [width]="32"
      alt="no available"
      src="/assets/sound-quality-not-available.svg"
    />
    </div>
    <div
      *ngIf="!soundQualityRating"
      class="d-flex rounded-circle align-items-center"
      [ngStyle]="{ 'background-color': reportStatus ? reportStatus.color : '' }"
    >
      <img
        [height]="68"
        [width]="68"
        alt="not available"
        src="/assets/not-available-rating.svg"
      /></div>
  </div>
</ng-template>
