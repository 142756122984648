import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { StageName } from './app/models/enums/stageName';

import { RmiModule } from './app/rmi.module';
import { environment } from './environments/environment';

if (environment.stage !== StageName.Local) {
  enableProdMode();
}

document.addEventListener('DOMContentLoaded', () => {
  platformBrowserDynamic()
    .bootstrapModule(RmiModule)
    .catch((err) => console.error(err));
});
