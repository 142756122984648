import {Component, OnInit} from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'rmi-ivr-analysis-report-footer',
  templateUrl: './ivr-analysis-report-footer.component.html',
  styleUrls: ['./ivr-analysis-report-footer.component.scss'],
})
export class IvrAnalysisReportFooterComponent implements OnInit{
  constructor(private modalService: ModalService) {}
  private landingPage = environment.website;
  year: string | undefined;

  ngOnInit() {
    this.year = String(new Date().getFullYear());
  }

  openReportProblemModal() {
    this.modalService.openReportProblemModal();
  }

  goToPrivacyPolicyPage = () => window.open(this.landingPage + '/privacy-policy.html', '_self')
  goToTermsOfService = () => window.open(this.landingPage + '/terms-of-service.html', '_self')
}
