import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { ContactUs } from 'src/app/models/contactUs.model';
import { FormState } from 'src/app/models/enums/formState.enum';
import { IvrService } from 'src/app/services/ivr.service';

@Component({
  selector: 'rmi-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss'],
})
export class ContactUsComponent {
  private readonly destroy$ = new Subject<void>();
  formState = FormState;
  public state$ = new BehaviorSubject(FormState.READY_FOR_SUBMISSION);
  formSentImageLoaded = false;

  form = this.fb.group({
    message: ['', [Validators.required]],
    name: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
  });
  errorMessage?: string;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private ivrService: IvrService
  ) {}

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();
      this.state$.next(FormState.LOADING);

      const data: ContactUs = {
        name: this.form.controls['name'].value,
        companyEmail: this.form.controls['email'].value,
        message: this.form.controls['message'].value,
      };
      this.ivrService
        .contactUs(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          complete: () => {
            this.state$.next(FormState.SUBMITTED);
            this.form.enable();
          },
          error: ({ error }) => {
            if (typeof error === 'string')
              this.errorMessage = error
            this.state$.next(FormState.ERROR);
            this.form.enable();
          },
        });
    }
  }

  onImageLoad() {
    this.formSentImageLoaded = true;
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  closeModal() {
    this.activeModal.close();
  }

  onInput(formControlName: string): void {
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res === 'INVALID') {
        this.form.controls[formControlName].markAllAsTouched();
      }
    });
  }

  onBlur(): void {
    this.form.markAsUntouched();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
