<div
  class="d-flex w-100 h-100 align-items-center justify-content-center flex-column"
  [ngSwitch]="ivrPageState"
>
  <div
    class="d-flex w-100 h-100 align-items-center justify-content-center flex-column wrapper"
    *ngSwitchCase="pageState.InProgress"
  >
    <img width="210" height="210" src="/assets/in-progress.svg" />
    <h2 i18n="@@ivr.processStarted">IVR roasting started</h2>
    <p i18n="@@ivr.processStartedDesc">
      We have started checking your IVR number, the process may take some time.
      As soon as the report is ready, we will send you an email notification.
    </p>
  </div>
  <div
    class="d-flex w-100 h-100 align-items-center justify-content-center flex-column wrapper"
    *ngSwitchCase="pageState.PendingApproval"
  >
    <img width="210" height="210" src="/assets/in-progress.svg" />
    <h2 i18n="@@ivr.processPendingApproval">Finalising your report</h2>
    <p i18n="@@ivr.processStartedDesc">
      We have started checking your IVR number, the process may take some time.
      As soon as the report is ready, we will send you an email notification.
    </p>
  </div>
  <div
    class="d-flex w-100 h-100 align-items-center justify-content-center flex-column wrapper"
    *ngSwitchCase="pageState.TimedOut"
  >
    <img width="210" height="210" src="/assets/timed-out.svg" />
    <h2 i18n="@@ivr.processTimedOut">IVR roasting timed out</h2>
    <p i18n="@@ivr.processTimedOutDesc">
      Hmm, it takes too long to roast your IVR. Let's find out why:
    </p>
    <button
      class="btn primary text-center mt-4"
      (click)="openReportProblemModal()"
    >
      <span i18n="@@common.reportProblem">Report a problem</span>
    </button>
  </div>
  <div
    class="d-flex w-100 h-100 align-items-center justify-content-center flex-column wrapper"
    *ngSwitchCase="pageState.Failed"
  >
    <img width="210" height="210" src="/assets/failed.svg" />
    <h2 i18n="@@ivr.processFailed">IVR roasting failed</h2>
    <p i18n="@@ivr.processFailedDesc">
      Oops, something went wrong. Help us figure out what's going on:
    </p>
    <button
      class="btn primary text-center mt-4"
      (click)="openReportProblemModal()"
    >
      <span i18n="@@common.reportProblem">Report a problem</span>
    </button>
  </div>
  <div
    class="d-flex w-100 h-100 align-items-center justify-content-center flex-column wrapper"
    *ngSwitchCase="pageState.IsNotIvr"
  >
    <img width="210" height="210" src="/assets/not-an-ivr.png" />
    <h2 i18n="@@ivr.isNotAnIvr">Roasted number is not an IVR</h2>
    <p i18n="@@ivr.isNotAnIvrDesc">
      Looks like a real person picked up the phone. Send a new request for IVR
      number roasting:
    </p>
    <button
      class="btn primary text-center mt-4"
      (click)="showIvrRoastingForm()"
    >
      <span i18n="@@common.roastMyIvr">Roast my IVR</span>!
    </button>
  </div>
  <div
    class="d-flex w-100 h-100 align-items-center justify-content-center flex-column wrapper"
    *ngSwitchCase="pageState.Expired"
  >
    <img width="210" height="210" src="/assets/expired.svg" />
    <h2 i18n="@@ivr.expired.title">Your report has expired</h2>
    <p i18n="@@ivr.expired.description">
      Please order a new one or contact us to access the old one:
    </p>
    <button class="btn primary text-center mt-4" (click)="openContactUsModal()">
      <span i18n="@@common.contactUs">Contact Us</span>!
    </button>
  </div>
  <div
    class="d-flex w-100 h-100 align-items-center justify-content-center flex-column wrapper"
    *ngSwitchDefault
  >
    <img width="210" height="210" src="/assets/error.svg" />
    <h2>Something went wrong</h2>
    <p>
      Oops! Something went wrong while fetching your information.
      This may be due to a temporary issue with our servers or a problem with your internet connection.
    </p>
    <button class="btn primary text-center mt-4" [routerLink]="'/'">
      <i class="material-icons-round me-10px">arrow_back</i>
      <span i18n="@@common.backToHome">Back to Home</span>
    </button>
  </div>
</div>
