import { Component } from '@angular/core';
import {environment} from "../../../../../environments/environment";

@Component({
  selector: 'rmi-test-another-ivr',
  templateUrl: './test-another-ivr.component.html',
  styleUrls: ['./test-another-ivr.component.scss']
})
export class TestAnotherIvrComponent {
  private landingPage = environment.website;
  goToIVRForm = () => {
    window.open(this.landingPage, '_self')
  }
}
