<div class="ps-32px position-relative">
  <div class="expand-line"></div>

  <div>
    <div
      class="menu position-relative"
      [ngClass]="{ 'no-audio': !menu.recordingUrl }"
      [id]="menu.menuId"
    >
      <i
        *ngIf="menu.menuItems.length"
        class="material-icons-round expand-btn"
        [ngStyle]="{ color: activeTab ? '#e42535' : '#1d3557' }"
        (click)="toggle()"
      >
        {{ activeTab ? "remove" : "add" }}
      </i>

      <div *ngIf="menu.recordingUrl">
        <rmi-waveform [audioSrc]="menu.recordingUrl"></rmi-waveform>
        <hr class="menu__dividing-line" />
      </div>

      <div>
        <div class="menu__text location"><span>location:</span> {{ path }}</div>
        <div
          class="menu__text"
          [innerHTML]="menu.englishTextHighlightedInputKeys || '' | safeHtml"
        ></div>
        <span class="d-flex flex-space justify-content-end align-items-end">
          <rmi-edit-report-button-by-admin
            (openRelatedEditModal)="openEditMenuContextModal(menu.menuId)"
          >
          </rmi-edit-report-button-by-admin>
          <rmi-remove-report-detail-button
            *ngIf="menu.menuId !== ivr.ivrReportVersion?.ivrMenu?.menuId"
            (deleteDetailOfReport)="deleteMenu.emit()"
          >
          </rmi-remove-report-detail-button>
        </span>
      </div>
    </div>

    <div class="menu-tabs mt-2">
      <ng-container *ngFor="let menuItem of menu.menuItems">
        <div
          *ngIf="!menuItem.returnToParent; else returnToParent"
          class="tab-btn"
          (click)="setTab(menuItem.inputKey)"
          [ngClass]="{ active: menuItem.inputKey === activeTab }"
          [id]="menuItem.menuItemId"
        >
          {{ menuItem.inputKey }}
        </div>

        <ng-template #returnToParent>
          <div
            class="tab-btn d-flex align-items-center justify-content-between"
            [id]="menuItem.menuItemId"
            [ngClass]="{ active: menuItem.inputKey === activeTab }"
            (click)="returnToParentMenu(menuItem.returnParentId!)"
          >
            <span class="tab-btn__return">{{ menuItem.inputKey }}</span>
            <img
              class="mt-2px ms-2"
              width="18"
              height="18"
              src="/assets/replay.svg"
            />
          </div>
        </ng-template>
      </ng-container>
    </div>

    <div class="display-flex flex-row">
      <ng-container *ngFor="let menuItem of menu.menuItems">
        <div
          class="mt-2 position-relative"
          *ngIf="menuItem.menu && menuItem.inputKey === activeTab"
        >
          <rmi-menu-ivr-tree
            [menu]="menuItem.menu"
            [path]="path + ' > ' + menuItem.inputKey"
            (deleteMenu)="deleteMenuItem(menu, menuItem.menuItemId)"
            (collapseParentMenu)="toggle($event)"
          ></rmi-menu-ivr-tree>
        </div>
      </ng-container>
    </div>
  </div>

  <div>
    <rmi-add-report-detail-button
      (openRelatedEditModal)="openAddNewMenuModal(menu.menuId)"
    >
    </rmi-add-report-detail-button>
  </div>
</div>
