<div class="d-flex w-100 flex-column">
  <h2 i18n="@@ivr.detailedAnalysis" class="title mb-4">Detailed analysis</h2>
  <div class="d-flex flex-column w-100 bg-white rounded-top-3">
    <div
      *ngIf="showEditingProvider"
      class="d-flex flex-column flex-sm-row justify-content-between m-md-32px m-3"
    >
      <div class="d-flex">
        <p i18n="@@ivr.currentProvider">Your current IVR provider:</p>
        &nbsp;
        <p>
          <strong>{{ lastIvrProvider }}</strong>
        </p>
      </div>
      <a *ngIf="canAddProvider" (click)="toggleShowReport()" class="link"
        ><span i18n="@@common.edit">Edit</span></a
      >
    </div>
    <div class="d-flex flex-column m-md-32px m-3" *ngIf="showChoosingProvider">
      <form
        class="form bg-white border-0"
        [formGroup]="form"
        *ngIf="state$ | async as state"
      >
        <p i18n="@@ivr.selectProvider">
          Please select your current IVR provider to see the detailed report:
        </p>
        <ng-select
          class="custom-ng-select mt-1 w-100"
          [items]="ivrProviders$ | async"
          bindLabel="providerName"
          bindValue="providerName"
          placeholder="Select or Enter"
          formControlName="provider"
          [addTag]="true"
        >
          id="provider" >
        </ng-select>
        <div
          class="error-text"
          *ngIf="
            form.controls.provider.touched &&
            form.controls.provider.errors?.required
          "
          i18n="@@contactForm.ivrProviderRequired"
        >
          Please select or enter your IVR provider
        </div>
        <div class="mt-4 d-flex flex-column">
          <Button
            *ngIf="
              state === formState.READY_FOR_SUBMISSION ||
              state === formState.ERROR
            "
            i18n="@@ivr.showDetails"
            (click)="addProviderToIvr()"
            class="btn primary text-center w-100 w-sm-none align-self-center"
            >Done, Show Me Details</Button
          >
          <button
            *ngIf="state === formState.LOADING"
            class="btn primary disabled text-center w-100 w-sm-none align-self-center"
            disabled
          >
            <span
              class="d-flex align-items-center justify-content-center button-wrapper"
            >
              <span
                class="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              ></span>
            </span>
          </button>
          <div
            class="d-felx mt-4 unknown-error text-center"
            *ngIf="state === formState.ERROR"
          >
            <div *ngIf="errorMessage !== undefined; else defaultError">
              {{ errorMessage }}
            </div>
            <ng-template i18n="@@contactForm.unknownError" #defaultError>
              Something went wrong, please try again.
            </ng-template>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="position-relative">
    <div class="blur" *ngIf="showChoosingProvider"></div>
    <div
      class="d-flex flex-column w-100 bg-white p-md-32px p-3 mt-2px"
      [ngClass]="{ 'rounded-top-3': !showEditingProvider }"
    >
      <div
        class="d-flex flex-column flex-sm-row rounded-2 justify-content-between px-12px py-sm-2 py-3 align-items-center"
        [ngStyle]="{
          'background-color':
            ivrReportVersion.ivrReport.speechQuality.colorWithOpacity
        }"
      >
        <h2 i18n="@@ivr.speechQualityTitle" class="m-0">Speech Quality</h2>
        <rmi-circle-progress
          class="circle-progress flex-shrink-0 d-block d-sm-none my-12px my-sm-0"
          [reportStatus]="ivrReportVersion.ivrReport.speechQuality"
        ></rmi-circle-progress>
        <rmi-report-status
          class="d-flex"
          [reportStatus]="ivrReportVersion.ivrReport.speechQuality"
        ></rmi-report-status>
      </div>
      <div class="d-flex mt-4 align-items-start">
        <rmi-circle-progress
          class="circle-progress flex-shrink-0 d-none d-sm-block"
          [reportStatus]="ivrReportVersion.ivrReport.speechQuality"
        ></rmi-circle-progress>
        <div class="d-flex flex-column ms-4 w-100">
          <p
            *ngIf="ivrReportVersion.ivrReport.speechQuality.description"
            [innerHTML]="
              ivrReportVersion.ivrReport.speechQuality.description | safeHtml
            "
          ></p>
        </div>
        <span class="align-self-end">
          <rmi-edit-report-button-by-admin
            (openRelatedEditModal)="openEditSpeechQualityStatusModal()"
          >
          </rmi-edit-report-button-by-admin>
        </span>
      </div>
    </div>
    <div class="d-flex flex-column w-100 bg-white p-md-32px p-3 mt-2px">
      <div
        class="d-flex flex-column flex-sm-row rounded-2 justify-content-between px-12px py-sm-2 py-3 align-items-center"
        [ngStyle]="{
          'background-color':
            ivrReportVersion.ivrReport.complexity.colorWithOpacity
        }"
      >
        <h2 i18n="@@ivr.complexityTitle" class="m-0">Complexity</h2>
        <img
          width="100"
          height="100"
          class="d-block d-sm-none my-12px my-sm-0"
          [src]="'/assets/' + ivrReportVersion.ivrReport.complexity.widget"
        />
        <rmi-report-status
          class="d-flex"
          [reportStatus]="ivrReportVersion.ivrReport.complexity"
        ></rmi-report-status>
      </div>
      <div class="d-flex mt-4 align-items-start">
        <img
          width="100"
          height="100"
          class="d-none d-sm-block"
          [src]="'/assets/' + ivrReportVersion.ivrReport.complexity.widget"
        />
        <div class="d-flex flex-column ms-4 w-100">
          <p
            *ngIf="ivrReportVersion.ivrReport.complexity.description"
            [innerHTML]="
              ivrReportVersion.ivrReport.complexity.description | safeHtml
            "
          ></p>
        </div>
        <span class="align-self-end">
          <rmi-edit-report-button-by-admin
            (openRelatedEditModal)="openEditComplexityReportStatusModal()"
          >
          </rmi-edit-report-button-by-admin>
        </span>
      </div>
    </div>
    <div class="d-flex flex-column w-100 bg-white p-md-32px p-3 mt-2px">
      <h2 i18n="@@ivr.soundQuality" class="m-0 w-100">Sound Quality</h2>
      <div class="w-100 d-flex flex-column mt-4">
        <div class="d-flex align-items-sm-center align-items-start">
          <rmi-score
            [soundQualityRating]="true"
            [large]="false"
            [reportStatus]="ivrReportVersion.ivrReport.audioNoise"
          ></rmi-score>
          <div class="d-flex flex-column flex-sm-row w-100">
            <div
              class="d-flex ms-4 justify-content-between flex-column flex-md-row"
            >
              <h3 i18n="@@ivr.noise" class="">Noise</h3>
            </div>
            <div
              class="w-100 justify-content-sm-end d-flex justify-content-start align-items-center"
            >
              <rmi-report-status
                [reportStatus]="ivrReportVersion.ivrReport.audioNoise"
              ></rmi-report-status>

              <rmi-edit-report-button-by-admin
                (openRelatedEditModal)="openEditSoundQualityNoiseScoreModal()"
              >
              </rmi-edit-report-button-by-admin>
            </div>
          </div>
        </div>
        <div class="dividing-line"></div>
        <div class="d-flex align-items-sm-center align-items-start">
          <rmi-score
            [soundQualityRating]="true"
            [large]="false"
            [reportStatus]="ivrReportVersion.ivrReport.audioMusic"
          ></rmi-score>
          <div class="d-flex flex-column flex-sm-row w-100">
            <div
              class="d-flex ms-4 justify-content-between flex-column flex-md-row"
            >
              <h3 i18n="@@ivr.music" class="">Music</h3>
            </div>
            <div
              class="w-100 justify-content-sm-end d-flex justify-content-start align-items-center"
            >
              <rmi-report-status
                [reportStatus]="ivrReportVersion.ivrReport.audioMusic"
              ></rmi-report-status>
              <rmi-edit-report-button-by-admin
                (openRelatedEditModal)="openEditSoundQualityMusicScoreModal()"
              >
              </rmi-edit-report-button-by-admin>
            </div>
          </div>
        </div>
        <div class="dividing-line"></div>
        <div class="d-flex align-items-sm-center align-items-start">
          <rmi-score
            [soundQualityRating]="true"
            [large]="false"
            [reportStatus]="ivrReportVersion.ivrReport.speechLoudness"
          ></rmi-score>
          <div class="d-flex flex-column flex-sm-row w-100">
            <div
              class="d-flex ms-4 justify-content-between flex-column flex-md-row"
            >
              <h3 i18n="@@ivr.loudness" class="">Loudness</h3>
            </div>
            <div
              class="w-100 justify-content-sm-end d-flex justify-content-start align-items-center"
            >
              <rmi-report-status
                [reportStatus]="ivrReportVersion.ivrReport.speechLoudness"
              ></rmi-report-status>
              <rmi-edit-report-button-by-admin
                (openRelatedEditModal)="
                  openEditSoundQualityLoudnessScoreModal()
                "
              >
              </rmi-edit-report-button-by-admin>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="d-flex flex-column w-100 bg-white p-md-32px p-3 mt-2px rounded-bottom-3"
    >
      <h2 i18n="@@ivr.qualitativeAnalysis" class="m-0 w-100">
        Qualitative Analysis
      </h2>
      <div class="w-100 d-flex flex-column mt-4">
        <div
          class="mb-3"
          *ngFor="
            let qualitativeAnalysis of ivrReportVersion.ivrReport
              .qualitativeAnalysis;
            let i = index
          "
        >
          <div class="d-flex">
            <rmi-analysis-shape
              [haveProblem]="qualitativeAnalysis.hasIssue"
            ></rmi-analysis-shape>
            <div class="d-flex flex-column ms-4">
              <p class="mb-2">
                {{ qualitativeAnalysis.text }}
              </p>
              <span
                *ngIf="
                  qualitativeAnalysis.hasIssue &&
                  qualitativeAnalysis.numberOfIssues &&
                  qualitativeAnalysis.numberOfIssues > 0
                "
                class="danger"
                >{{ qualitativeAnalysis.numberOfIssues }} found</span
              >
              <span *ngIf="qualitativeAnalysis.description" class="detail">{{
                qualitativeAnalysis.description
              }}</span>
            </div>
          </div>
          <div>
            <div class="d-flex justify-content-end">
              <rmi-edit-report-button-by-admin
                (openRelatedEditModal)="openQualitativeAnalysisModal(i)"
              >
              </rmi-edit-report-button-by-admin>
              <rmi-remove-report-detail-button
                (deleteDetailOfReport)="deleteDetailOfReport(i)"
              >
              </rmi-remove-report-detail-button>
            </div>
          </div>
          <div
            *ngIf="
              ivrReportVersion.ivrReport.qualitativeAnalysis.length !== i + 1
            "
            class="dividing-line"
          ></div>
        </div>
        <div>
          <rmi-add-report-detail-button
            (openRelatedEditModal)="openQualitativeAnalysisModal(undefined)"
          >
          </rmi-add-report-detail-button>
        </div>
      </div>
    </div>
  </div>
</div>
