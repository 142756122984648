import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Ivr } from 'src/app/models/ivr.model';
import { Menu } from 'src/app/models/menu.model';
import { ModalService } from 'src/app/services/modal.service';
import { ScrollerService } from 'src/app/services/scroller.service';
import { IvrDatasourceService } from 'src/app/shared/data/ivr-datasource.service';

@Component({
  selector: 'rmi-menu-ivr-tree',
  templateUrl: './menu-ivr-tree.component.html',
  styleUrls: ['./menu-ivr-tree.component.scss'],
})
export class MenuIvrTreeComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();

  // @ts-ignore
  @Input() menu: Menu;
  @Input() path: string = '';

  @Output() deleteMenu: EventEmitter<void> = new EventEmitter();
  @Output() collapseParentMenu: EventEmitter<string> = new EventEmitter();

  activeTab: string = '';

  // @ts-ignore
  ivr: Ivr;

  setTab(tab: string) {
    this.activeTab = tab;
  }

  constructor(
    private modalService: ModalService,
    private ivrDataSource: IvrDatasourceService
  ) {}

  toggle(returnParentId?: string) {
    if (!this.menu) {
      return
    }

    if (returnParentId && returnParentId !== this.menu?.menuId) {
      this.collapseParentMenu.emit(returnParentId);
    }
    if (this.activeTab) {
      this.activeTab = '';
    } else {
      if (this.menu?.menuItems.length === 1 && this.menu?.menuItems[0].returnToParent) {
        this.scroll(this.menu.menuItems[0].returnParentId!);
      } else if (this.menu?.menuItems?.length > 1 && this.menu?.menuItems[0].returnToParent) {
        this.activeTab = this.menu.menuItems[1].inputKey;
      } else {
        this.activeTab = this.menu?.menuItems[0].inputKey ?? '';
      }
    }
  }

  ngOnInit() {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => (this.ivr = ivr));
  }

  scroll(elementId: string) {
    ScrollerService.scrollToId(elementId);
  }

  openEditMenuContextModal(menuId: string) {
    this.modalService.openEditMenuContextModal(menuId);
  }

  openAddNewMenuModal(menuId: string) {
    this.modalService.openAddNewMenuModal(menuId);
  }

  deleteMenuItem(menu: Menu | null, menuItemId: string): void {
    if (menu) {
      if (
        menu.menuItems.filter((menuItem) => menuItem.menuItemId === menuItemId)
      ) {
        menu.menuItems = menu.menuItems.filter(
          (menuItem) => menuItem.menuItemId !== menuItemId
        );
      } else {
        for (const menuItem of menu.menuItems) {
          this.deleteMenuItem(menuItem.menu, menuItemId);
        }
      }
    }
  }

  returnToParentMenu(returnParentId: string) {
    this.scroll(returnParentId);
    if (returnParentId !== this.menu?.menuId) {
      this.collapseParentMenu.emit(returnParentId);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
