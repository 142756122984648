import { ShareReport } from '../../../../models/shareReport.model';
import { IvrDatasourceService } from '../../../data/ivr-datasource.service';
import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormState } from 'src/app/models/enums/formState.enum';
import { IvrService } from 'src/app/services/ivr.service';
import { Ivr } from 'src/app/models/ivr.model';

@Component({
  selector: 'rmi-report-sharing',
  templateUrl: './report-sharing.component.html',
  styleUrls: ['./report-sharing.component.scss'],
})
export class ReportSharingComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  formState = FormState;
  public state$ = new BehaviorSubject(FormState.READY_FOR_SUBMISSION);
  formSentImageLoaded = false;
  private ivrId: string = '';

  form = this.fb.group({
    email: ['', [Validators.required, Validators.email]],
  });
  errorMessage: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private ivrService: IvrService,
    private ivrDataSource: IvrDatasourceService
  ) {}

  public submit(): void {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => {
        this.ivrId = ivr.ivrId;
      });
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();
      this.state$.next(FormState.LOADING);
      const data: ShareReport = {
        email: this.form.controls['email'].value,
        ivrId: this.ivrId,
      };
      this.ivrService
        .shareIvrByEmail(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          complete: () => {
            this.state$.next(FormState.SUBMITTED);
            this.form.enable();
          },
          error: ({ error }) => {
            if (typeof error === 'string') this.errorMessage = error;
            this.state$.next(FormState.ERROR);
            this.form.enable();
          },
        });
    }
  }

  onImageLoad() {
    this.formSentImageLoaded = true;
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  shareAgain() {
    this.form.reset();
    this.state$.next(FormState.READY_FOR_SUBMISSION);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onInput(): void {
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res === 'INVALID') {
        this.form.markAllAsTouched();
      }
    });
  }

  onBlur(): void {
    this.form.markAsUntouched();
  }
}
