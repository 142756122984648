<rmi-ivr-analysis-report-header
  [showEmail]="false"
></rmi-ivr-analysis-report-header>
<div class="pb-lg-72px pb-md-48px pb-5 custom-container report-not-found">
  <div class="dividing-line rounded-2 w-100"></div>
  <div class="d-flex flex-column align-items-center justify-content-center report-not-found-content">
    <img class="mb-4" src="/assets/not-found.svg" alt="not-found" />
    <h1 i18n="@@notFound.title">Page not found</h1>
    <p i18n="@@notFound.body">
      Looks like the page you're looking for doesn't exist.
    </p>
    <button
      class="btn primary text-center my-4 d-flex align-items-center p-20px"
      [routerLink]="'/'"
    >
      <i class="material-icons-round me-10px">arrow_back</i>
      <span i18n="@@common.backToHome">Back to Home</span>
    </button>
  </div>
</div>
<rmi-ivr-analysis-report-footer></rmi-ivr-analysis-report-footer>
