<div
  class="d-flex flex-column align-items-center bg-white rounded-3 position-relative modal-warper"
>
  <img
    (click)="dismissModal()"
    class="cross-btn"
    width="18"
    height="18"
    src="/assets/cross.svg"
  />
  <h2
    i18n="@@common.editComplexityStatus"
    class="mt-40px mb-4 text-center d-inline-block header"
  >
    Edit Recommendation
  </h2>

  <div class="w-100 p-3">
    <form
      class="form p-32px"
      *ngIf="ivr"
      [formGroup]="form"
      (ngSubmit)="submit()"
    >
      <div class="field">
        <label for="recommendation" class="form-label">Recommendation:</label>
        <textarea
          (input)="onInput()"
          (blur)="onBlur()"
          class="form-control"
          id="recommendation"
          formControlName="recommendation"
        >
        </textarea>
      </div>
      <div class="field">
        <label for="recommendaionPriority" class="form-label">Priority:</label>
        <ng-select
          class="custom-ng-select mt-1 w-100"
          id="recommendaionPriority"
          placeholder="Select the Priority "
          formControlName="recommendationPriority"
        >
          <ng-option
            *ngFor="
              let reportRecommendationsOrderOption of reportRecommendationsOrderOptions;
              trackBy: identify
            "
            [value]="reportRecommendationsOrderOption.value"
            >{{ reportRecommendationsOrderOption.key }}</ng-option
          >
        </ng-select>
      </div>
      <div class="d-flex justify-content-center p-2">
        <button class="btn primary text-center" type="submit">
          <span i18n="@@common.save">Save</span>
        </button>
      </div>
    </form>
  </div>
</div>
