<div
  class="d-flex flex-column align-items-center bg-white rounded-3 position-relative modal-warper"
>
  <img
    (click)="dismissModal()"
    class="cross-btn"
    width="18"
    height="18"
    src="/assets/cross.svg"
  />
  <h2
    i18n="@@common.editComplexityStatus"
    class="mt-40px mb-4 text-center d-inline-block header"
  >
    Edit Complexity Status
  </h2>
  <div class="w-100 p-3">
    <form
      class="form p-32px"
      *ngIf="ivr"
      [formGroup]="form"
      (ngSubmit)="submit()"
    >
      <div class="field">
        <label for="complexityStatus" class="form-label"
          >Complexity Status:</label
        >
        <ng-select
          class="custom-ng-select mt-1 w-100"
          id="complexityStatus"
          placeholder="Select Complexity Status"
          formControlName="statusText"
          (change)="onChangeStatus($event)"
        >
          <ng-option
            *ngFor="
              let reportStatusNameOption of reportStatusNameOptions;
              trackBy: identify
            "
            [value]="reportStatusNameOption.value"
            >{{ reportStatusNameOption.value }}</ng-option
          >
        </ng-select>
      </div>
      <div class="field" *ngIf="showScoreAndColor">
        <label for="complexityWidget" class="form-label"
          >Complexity Widget:</label
        >
        <ng-select
          class="custom-ng-select mt-1 w-100"
          id="complexityWidget"
          placeholder="Select Complexity Widget"
          formControlName="widget"
        >
          <ng-option
            *ngFor="
              let reportStatusWidgetOption of reportStatusWidgetOptions;
              trackBy: identify
            "
            [value]="reportStatusWidgetOption.value"
          >
            <img
              width="50"
              height="25"
              [src]="'/assets/' + reportStatusWidgetOption.value"
            />
            {{ reportStatusWidgetOption.key }}</ng-option
          >
        </ng-select>
      </div>
      <div class="field" *ngIf="showScoreAndColor">
        <label for="complexityStatusColor" class="form-label"
          >Status Color:</label
        >
        <ng-select
          class="custom-ng-select mt-1 w-100"
          id="complexityStatusColor"
          placeholder="Select Status Color"
          formControlName="color"
        >
          <ng-option
            *ngFor="
              let reportStatusColorOption of reportStatusColorOptions;
              trackBy: identify
            "
            [value]="reportStatusColorOption.value"
          >
            <div class="d-flex">
              <div
                [ngStyle]="{ backgroundColor: reportStatusColorOption.value }"
                class="color-sample-container"
              ></div>
              {{ reportStatusColorOption.key }}
            </div></ng-option
          >
        </ng-select>
      </div>
      <div class="field" *ngIf="showScoreAndColor">
        <label for="ComplexityDescription" class="form-label"
          >Complexity Description:</label
        >
        <quill-editor
          formControlName="description"
          [modules]="module"
        ></quill-editor>
      </div>
      <div class="d-flex justify-content-center p-2">
        <button class="btn primary text-center" type="submit">
          <span i18n="@@common.save">Save</span>
        </button>
      </div>
    </form>
  </div>
</div>
