import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Ivr } from 'src/app/models/ivr.model';
import { IvrDatasourceService } from 'src/app/shared/data/ivr-datasource.service';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'rmi-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss'],
})
export class RecommendationsComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  recommendations: string[] = [];
  constructor(
    private ivrDataSource: IvrDatasourceService,
    private modalService: ModalService
  ) {}

  ngOnInit() {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (ivr: Ivr) =>
          (this.recommendations =
            ivr.ivrReportVersion!.ivrReport.recommendations)
      );
  }

  openEditRecommendationModal(index: number | undefined) {
    this.modalService.openEditRecommendationModal(index);
  }

  deleteRecomendationOfReport(index: number) {
    this.recommendations =
      this.recommendations.filter(recommend => recommend !== this.recommendations[index]);
    this.ivrDataSource.ivr$
    .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (ivr: Ivr) => {
          (ivr.ivrReportVersion!.ivrReport.recommendations =
            this.recommendations);
        }
    }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
