<div
  class="d-flex flex-column align-items-center bg-white rounded-3 position-relative modal-warper"
>
  <img
    (click)="dismissModal()"
    class="cross-btn"
    width="18"
    height="18"
    src="/assets/cross.svg"
  />
  <h2
    i18n="@@common.editOverallScore"
    class="mt-40px mb-4 text-center d-inline-block header"
  >
    Edit Overall Estimation
  </h2>
  <div class="w-100 p-3">
    <form
      class="form p-32px"
      *ngIf="ivr"
      [formGroup]="form"
      (ngSubmit)="submit()"
    >
      <div class="field">
        <label for="overallStatus" class="form-label">Overall Status</label>
        <ng-select
          #overallStatus
          class="custom-ng-select mt-1 w-100"
          id="overallStatus"
          placeholder="Select Overall Status"
          formControlName="statusText"
          (change)="onChangeStatus($event)"
        >
          <ng-option
            *ngFor="
              let reportStatusNameOption of reportStatusNameOptions;
              trackBy: identify
            "
            [value]="reportStatusNameOption.value"
            >{{ reportStatusNameOption.value }}</ng-option
          >
        </ng-select>
      </div>
      <div class="field" *ngIf="showScoreAndColor">
        <label for="overallScore" class="form-label">Overall Score</label>
        <ng-select
          class="custom-ng-select mt-1 w-100"
          id="overallScore"
          placeholder="Select Overall Score"
          formControlName="stars"
        >
          <ng-option
            *ngFor="
              let reportStatusStarOption of reportStatusStarOptions;
              trackBy: identify
            "
            [value]="reportStatusStarOption.value"
            >{{ reportStatusStarOption.key }}</ng-option
          >
        </ng-select>
      </div>
      <div class="field" *ngIf="showScoreAndColor">
        <label for="overallStatusColor" class="form-label">Status Color</label>
        <ng-select
          class="custom-ng-select mt-1 w-100"
          id="overallStatusColor"
          placeholder="Select Status Color"
          formControlName="color"
          (change)="onChangeColor($event)"
        >
          <ng-option
            *ngFor="
              let reportStatusColorOption of reportStatusColorOptions;
              trackBy: identify
            "
            [value]="reportStatusColorOption.value"
          >
            <div class="d-flex">
              <div
                [ngStyle]="{ backgroundColor: reportStatusColorOption.value }"
                class="color-sample-container"
              ></div>
              {{ reportStatusColorOption.key }}
            </div></ng-option
          >
        </ng-select>
      </div>
      <div class="d-flex justify-content-center p-2">
        <button class="btn primary text-center" type="submit">
          <span i18n="@@common.save">Save</span>
        </button>
      </div>
    </form>
  </div>
</div>
