import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Captcha } from 'src/app/models/captcha.model';
import { IvrPageStateEnum } from 'src/app/models/enums/ivrPageState.enum';
import { IvrProcessStatus } from 'src/app/models/enums/IvrProcessStatus.enum';
import { Ivr } from 'src/app/models/ivr.model';

@Injectable({
  providedIn: 'root',
})
export class IvrDatasourceService {
  public captchaValidity$ = new Subject<boolean>();
  public captchaData$ = new BehaviorSubject<Captcha>({
    question: '',
    answer: '',
    token: '',
  });

  public loading$ = new BehaviorSubject(true);
  public ivr$ = new BehaviorSubject<Ivr>({
    finishProcessDate: '',
    industry: { branchingFactor: 0, depth: 0, industryId: 0, industryName: '' },
    isIvr: false,
    ivrId: '',
    language: '',
    processStatus: IvrProcessStatus.InProgress,
    ivrProvider: '',
    startProcessDate: '',
    telephoneNumber: '',
    canAddProvider: false,
    canEditReport: false,
  });
  ivrPageState$ = new BehaviorSubject<IvrPageStateEnum>(
    IvrPageStateEnum.Error
  );
  public showEditReportButtons$ = new BehaviorSubject(false);
  constructor() {}
}
