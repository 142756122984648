<div class="p-4 cookie-container">
  <div class="d-flex flex-column flex-md-row align-items-center">
    <img src="/assets/cookie.svg" alt="cookie" />
    <h3 i18n="@@cookie.title" class="ms-md-3 ms-0 mt-md-0 mt-2">
      This website uses cookies
    </h3>
  </div>
  <p i18n="@@cookie.desc" class="mt-3 mb-3 mb-md-4 text-center text-md-start">
    We use the anonymous data provided by cookies to enhance your browsing
    experience and analyze our traffic. By clicking "Allow All", you consent to
    our use of cookies.
  </p>
  <div
    class="d-flex justify-content-md-between align-items-center flex-column flex-md-row"
  >
    <a class="link link-medium" [routerLink]="['/privacy-policy']"
      ><span i18n="@@common.policy">Privacy policy</span></a
    >
    <div class="btn-wrapper d-flex flex-column-reverse flex-md-row">
      <button
        (click)="setCookie(false)"
        i18n="@@common.deny"
        class="btn outline me-md-3 text-center mt-2 mt-md-0"
      >
        Deny
      </button>
      <button
        (click)="setCookie(true)"
        i18n="@@common.allowAll"
        class="btn primary text-center mt-4 mt-md-0"
      >
        Allow All
      </button>
    </div>
  </div>
</div>
