<div calss="d-flex flex-column align-items-start" *ngIf="show">
    <button 
    class="btn outline add-button d-none d-md-flex"
    (click)="openRelatedEditModal.emit()"
    i18n="@@common.addNotes"
    >
    Add Notes
    </button>
  </div>
  
