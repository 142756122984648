import { Component, Input } from '@angular/core';

@Component({
  selector: 'rmi-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent {
  @Input() extraInformation: string = '';
}
