<div
  class="d-flex flex-row justify-content-center align-items-center py-4 bg-gray w-100 text-center"
>
  <h4 i18n="@@ivr.testAnotherIvr" class="m-0">Test another IVR on</h4>
  <div class="ms-3">
    <a class="link" (click)="goToIVRForm()">
      <img width="32" height="32" src="/assets/logo.svg" /><span class="ms-2"
        >roastmyivr.com</span
      ></a
    >
  </div>
</div>
