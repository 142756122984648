<div class="custom-container bg-white header my-md-4 my-3">
  <div class="d-flex align-items-center justify-content-between">
    <div class="d-flex align-items-center">
      <div class="header__logo d-flex align-items-center">
        <img width="48" height="64" src="/assets/logo.svg" alt="Logo" />
        <span class="ms-3" i18n="@@common.title">Roast My IVR</span>
      </div>
      <div class="header__title d-xl-flex ms-5 d-none">
        <h2 i18n="@@ivr.analysisReport">IVR analysis report</h2>
        <span i18n="@@common.free">FREE</span>
      </div>
    </div>
    <span *ngIf="showEmail">
      <div
        class="d-flex align-items-center"
        *ngIf="!showSkeleton; else skeletonLoader"
      >
      <a (click)="openReportSharingModal()" class="link header__share">
        <i class="material-icons-round">share</i>
        <span class="d-none d-md-block" i18n="@@common.shareByEmail"
        >Share by email</span
        ></a
      >
    </div>
    </span>

  </div>
</div>

<ng-template #skeletonLoader>
  <div class="header-top-bar-skeleton d-sm-block d-none">
    <ngx-skeleton-loader
      [theme]="{
        'border-radius': '8px',
        height: '24px',
        width: '157px',
        'background-color': 'rgba(0, 0, 0, 0.07)'
      }"
      appearance="line"
    ></ngx-skeleton-loader>
  </div>
  <div class="d-sm-none">
    <ngx-skeleton-loader
      [theme]="{
        'border-radius': '8px',
        height: '32px',
        width: '32px',
        'background-color': 'rgba(0, 0, 0, 0.07)'
      }"
      appearance="line"
    ></ngx-skeleton-loader>
  </div>
</ng-template>
