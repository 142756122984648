import {Component} from '@angular/core';
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'rmi-ivr-analysis-report-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent {
  private landingPage = environment.website;
  public showIvrRoastingForm() {
      window.open(this.landingPage + '#ivr', '_self')
  }
}
