import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IvrDatasourceService } from '../../data/ivr-datasource.service';

@Component({
  selector: 'rmi-remove-report-detail-button',
  templateUrl: './remove-report-detail-button.component.html',
  styleUrls: ['./remove-report-detail-button.component.scss'],
})
export class RemoveReportDetailButtonComponent implements OnInit, OnDestroy {
  @Output() deleteDetailOfReport: EventEmitter<void> = new EventEmitter();

  private readonly destroy$ = new Subject<void>();

  show = false;

  constructor(private ivrDataSource: IvrDatasourceService) {}

  ngOnInit(): void {
    this.ivrDataSource.showEditReportButtons$
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (showDeleteReportButton: boolean) =>
          (this.show = showDeleteReportButton)
      );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
