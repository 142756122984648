<div
  class="d-flex flex-column align-items-center bg-white rounded-3 position-relative modal-warper"
>
  <img
    (click)="dismissModal()"
    class="cross-btn"
    width="18"
    height="18"
    src="/assets/cross.svg"
  />
  <h2
    i18n="@@common.contactUs"
    class="mt-40px mb-4 text-center d-inline-block header"
  >
    Contact Us
  </h2>
  <div class="w-100 p-3" *ngIf="state$ | async as state">
    <form
      class="form p-32px"
      [formGroup]="form"
      (ngSubmit)="submit()"
      *ngIf="
        state === formState.LOADING ||
        state === formState.READY_FOR_SUBMISSION ||
        state === formState.ERROR
      "
    >
      <div class="field">
        <label for="name" class="form-label" i18n="@@contactForm.name"
          >Your name:</label
        >
        <input
          type="text"
          class="form-control"
          id="name"
          formControlName="name"
          (input)="onInput('name')"
          (blur)="onBlur()"
        />
      </div>
      <div class="field email-bottom-padding">
        <label for="email" class="form-label" i18n="@@contactForm.email"
        >Email:</label
        >
        <input
          type="email"
          class="form-control"
          id="email"
          formControlName="email"
          (input)="onInput('email')"
          (blur)="onBlur()"
        />
      </div>
      <div class="field">
        <label for="email" class="form-label">Message:</label>
        <textarea
          (input)="onInput('message')"
          (blur)="onBlur()"
          class="form-control"
          id="message"
          formControlName="message"
        >
        </textarea>
      </div>
      <div class="d-flex justify-content-center">
        <button
          *ngIf="
            state === formState.READY_FOR_SUBMISSION ||
            state === formState.ERROR
          "
          class="btn primary text-center"
          type="submit"
        >
          <span i18n="@@common.send">Send!</span>
        </button>
        <button
          *ngIf="state === formState.LOADING"
          class="btn primary disabled"
          disabled
        >
          <span
            class="d-flex align-items-center justify-content-center button-wrapper"
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
          </span>
        </button>
      </div>
      <div
        class="d-felx mt-4 unknown-error text-center"
        *ngIf="state === formState.ERROR"
      >
        <div *ngIf="errorMessage !== undefined; else defaultError">
          {{ errorMessage }}
        </div>
        <ng-template i18n="@@contactForm.unknownError" #defaultError>
          Something went wrong, please try again.
        </ng-template>
      </div>
      <div class="errors-container">
        <div
          class="error-text"
          *ngIf="
            form.controls.name.touched && form.controls.name.errors?.required
          "
          i18n="@@contactForm.nameRequired"
        >
          Name is required
        </div>
        <div
          class="error-text"
          *ngIf="
            form.controls.email.touched && form.controls.email.errors?.required
          "
          i18n="@@contactForm.emailRequired"
        >
          Email is required
        </div>
        <div
          class="error-text"
          *ngIf="
            form.controls.email.touched && form.controls.email.errors?.email
          "
          i18n="@@contactForm.invalidEmail"
        >
          Please enter valid email
        </div>
        <div
          class="error-text"
          *ngIf="
            form.controls.message.touched &&
            form.controls.message.errors?.required
          "
          i18n="@@contactForm.messageRequired"
        >
          Message is required
        </div>
      </div>
    </form>
    <div
      class="submit-success d-flex flex-column align-items-center"
      *ngIf="state === formState.SUBMITTED"
    >
      <img
        src="/assets/success.svg"
        alt="Form sent"
        i18n-alt="@@common.formSent"
        (load)="onImageLoad()"
        class="mt-32px mb-4"
      />
      <div
        class="d-flex flex-column text-center pt-0 p-32px"
        *ngIf="formSentImageLoaded"
      >
        <span class="title" i18n="@@common.thankForMessage"
          >Thank you for message!</span
        >
        <span class="subtitle" i18n="@@common.contactShortly"
          >We will contact you shortly.</span
        >

        <button
          class="btn outline align-self-center mt-4"
          i18n="@@common.close"
          (click)="closeModal()"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div>
