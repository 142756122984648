<div class="audio-container">
  <span *ngIf="!isAudioAvailable && isLoading">Loading... </span>
  <span *ngIf="!isLoading">Audio is not available </span>
  <button
    *ngIf="isAudioAvailable"
    (click)="togglePlay()"
    class="audio-container__btn"
    [ngClass]="
      playing ? 'audio-container__btn--pause' : 'audio-container__btn--play'
    "
  ></button>
  <canvas
    *ngIf="isLoading"
    [height]="height"
    class="audio-container__waveform"
    #audioCanvas
    (mousemove)="waveFormMouseMove($event)"
    (mouseleave)="waveFormMouseLeave()"
    (click)="waveFormClick()"
  ></canvas>
  <span
    *ngIf="isAudioAvailable"
    class="audio-container__timer"
    [innerHTML]="
      playTime === 0
        ? (audioBuffer?.duration | formatAudioPlayerTime)
        : (playTime | formatAudioPlayerTime)
    "
  ></span>
</div>
