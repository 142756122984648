<rmi-overall-report></rmi-overall-report>
<div
  class="custom-container py-32px pt-md-40px bg-gray w-100 d-flex justify-content-center report-middle-parent-container"
>
  <div class="d-flex flex-column flex-lg-row justify-content-xxl-between w-100">
    <div
      class="col-lg-6 col-12 pe-0 pe-lg-2 pe-xxl-unset report-middle-container"
    >
      <rmi-discovered-menu></rmi-discovered-menu>
    </div>
    <div
      class="col-lg-6 col-12 ps-0 ps-lg-2 ps-xxl-unset mt-32px mt-sm-40px mt-lg-0 report-middle-container"
    >
      <rmi-detailed-analysis></rmi-detailed-analysis>
    </div>
  </div>
</div>
<div
  class="custom-container py-32px pb-md-3 pt-md-40px w-100 d-flex justify-content-center"
>
  <div class="d-flex flex-column flex-lg-row">
    <div class="col-lg-6 col-12 pe-lg-2 pe-0">
      <rmi-recommendations></rmi-recommendations>
    </div>
    <div class="col-lg-6 col-12 ps-lg-2 ps-0 mt-32px mt-sm-40px mt-lg-0">
      <rmi-how-about-improvements></rmi-how-about-improvements>
    </div>
  </div>
</div>
<div class="w-100 d-flex justify-content-center" *ngIf="extraInformation">
  <div class="d-flex flex-column flex-lg-row">
    <rmi-notes extraInformation="{{ extraInformation }}"></rmi-notes>
  </div>
</div>
<div
  class="d-flex justify-content-center align-items-start mb-40px mt-4"
  *ngIf="canEditReport"
>
  <rmi-add-note-button (openRelatedEditModal)="openReportEditorNoteModal()">
  </rmi-add-note-button>
  <rmi-save-changes-button
    (openRelatedEditModal)="openSaveEditorReportChangesModal()"
  >
  </rmi-save-changes-button>
</div>
<rmi-test-another-ivr></rmi-test-another-ivr>
<div
  class="floating-btn d-sm-none improve-float-btn"
  i18n="@@common.improveMyIvr"
  (click)="openImprovementRequestModal()"
>
  Improve My IVR!
</div>
