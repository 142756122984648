import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StageName } from '../models/enums/stageName';
import {
  TrackerEvent,
  trackerEvent,
  trackerIdentityWithUserProfileIfExists,
  trackerInit
} from '../shared/utilities/tracker';
import { CookieAccessStatus, CookieManagerService } from './cookie-manager.service';

@Injectable({
  providedIn: 'root',
})
export class FullstoryService {
  fullstoryInit: Subject<void> = new Subject();
  constructor(
    private cookieManager: CookieManagerService,
  ) { }

  trackerEvent(event: TrackerEvent, opts?: { [key: string]: any }): void {
    if (this.canUseFullstory()) {
      trackerEvent(event, opts);
    }
  }

  setFullStory(): void {
    if (this.canUseFullstory()) {
      trackerInit();
      if (trackerIdentityWithUserProfileIfExists()) {
        trackerEvent(TrackerEvent.ACTION_RESTORE_SESSION);
      }
    }
  }

  canUseFullstory(): boolean {
    return (
      environment.stage !== StageName.Local &&
      environment.stage !== StageName.Development &&
      this.cookieManager.allowedCookie == CookieAccessStatus.IsAllowed
    )
  }
}
