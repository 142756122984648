<rmi-ivr-analysis-report-header
  [showEmail]="false"
></rmi-ivr-analysis-report-header>
<div class="custom-container report-landing w-100">
  <div class="dividing-line rounded-2 w-100"></div>
  <div
    class="d-flex flex-column align-items-center justify-content-center report-landing-content pb-lg-72px pb-md-48px pb-5 pt-44px"
  >
    <img class="mb-4" src="/assets/error.svg" alt="no-report" />
    <h1>No report</h1>
    <p>
      No report available. After completing the required tests, you will receive
      the report link in your email. You can then use the link to access and
      view the report on this page.
    </p>
    <button
      class="btn primary text-center my-4 d-flex align-items-center p-20px"
      (click)="showIvrRoastingForm()"
    >
      <span>Test my IVR now!</span>
    </button>
  </div>
</div>
<rmi-ivr-analysis-report-footer></rmi-ivr-analysis-report-footer>
