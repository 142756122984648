import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormState } from 'src/app/models/enums/formState.enum';
import { Ivr } from 'src/app/models/ivr.model';
import { IvrReportVersion } from 'src/app/models/ivrReportVersion.model';
import { IvrReportVersionReqBody } from 'src/app/models/ivrReportVersionReqBody.model';
import { IvrService } from 'src/app/services/ivr.service';
import { IvrDatasourceService } from 'src/app/shared/data/ivr-datasource.service';

@Component({
  selector: 'rmi-save-editor-report-changes',
  templateUrl: './save-editor-report-changes.component.html',
  styleUrls: ['./save-editor-report-changes.component.scss'],
})
export class SaveEditorReportChangesComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  formState = FormState;
  formSentImageLoaded = false;
  public state$ = new BehaviorSubject(FormState.READY_FOR_SUBMISSION);
  public ivr: Ivr | undefined;

  // @ts-ignore
  form: FormGroup;
  errorMessage?: string;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private ivrService: IvrService,
    private ivrDataSource: IvrDatasourceService
  ) {}

  ngOnInit(): void {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => {
        this.form = this.fb.group({
          versionName: [ivr.ivrReportVersion!.versionName],
          editCurrentVersion: [true],
          setDefaultVersion: [true],
        });
        this.ivr = ivr;
      });
  }

  public submit(): void {
    if (!this.form) {
      return;
    }
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();
      this.state$.next(FormState.LOADING);
      if (!this.ivr) {
        return
      }
      const data: IvrReportVersionReqBody = {
        ivrMenu: this.ivr.ivrReportVersion!.ivrMenu,
        ivrReport: this.ivr.ivrReportVersion!.ivrReport,
        editCurrentVersion: this.form.controls['editCurrentVersion'].value,
        setDefaultVersion: this.form.controls['setDefaultVersion'].value,
        versionName: this.form.controls['versionName'].value,
        versionId: this.ivr.ivrReportVersion!.versionId,
        ivrId: this.ivr.ivrId,
      };
      this.ivrService
        .saveIvrReportVersion(data)
        .pipe(takeUntil(this.destroy$))
        .subscribe({
          next: (data: IvrReportVersion) => {
            if (!this.ivr) {
              return
            }
            this.ivr.ivrReportVersion = data;
            this.ivrDataSource.ivr$.next(this.ivr);
            this.state$.next(FormState.SUBMITTED);
            this.form?.enable();
          },
          error: ({ error }) => {
            if (typeof error === 'string') this.errorMessage = error;
            this.state$.next(FormState.ERROR);
            this.form?.enable();
          },
        });
    }
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  onInput(): void {
    if (!this.form) {
      return;
    }
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res === 'INVALID') {
        this.form?.markAllAsTouched();
      }
    });
  }

  onBlur(): void {
    if (!this.form) {
      return;
    }
    this.form.markAsUntouched();
  }

  toggleCurrentVersion(event: any): void {
    if (!this.form) {
      return;
    }
    if (this.form.controls['editCurrentVersion'].value) {
      this.form.controls['versionName'].setValue(
        this.ivr?.ivrReportVersion!.versionName
      );
    } else {
      this.form.controls['versionName'].setValue(new Date().toLocaleString());
    }
  }

  onImageLoad() {
    this.formSentImageLoaded = true;
  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
