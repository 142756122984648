import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';

export enum CookieAccessStatus {
  IsUndefined = "IsUndefined",
  IsNotBrowser = "IsNotBrowser",
  IsDenied = "IsDenied",
  IsAllowed = "IsAllowed"
}

@Injectable({
  providedIn: 'root',
})
export class CookieManagerService {
  private _allowCookie = CookieAccessStatus.IsUndefined;
  // @ts-ignore
  private _cookies: CookieService;

  constructor(
    private readonly cookieService: CookieService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this._allowCookie = this.getAllowCookiesInitiateValue()
    this.updateCookieInstance();
  }

  private getAllowCookiesInitiateValue() {
    if (!isPlatformBrowser(this.platformId))
      return CookieAccessStatus.IsNotBrowser
    const storedValue = localStorage.getItem(environment.allowCookie);
    if (storedValue == CookieAccessStatus.IsDenied)
      return CookieAccessStatus.IsDenied
    if (storedValue == CookieAccessStatus.IsAllowed)
      return CookieAccessStatus.IsAllowed
    return CookieAccessStatus.IsUndefined
  }

  public get allowedCookie() {
    return this._allowCookie;
  }

  public set allowedCookie(allowCookie: CookieAccessStatus) {
    localStorage.setItem(environment.allowCookie, allowCookie)
    this._allowCookie = allowCookie;
    this.updateCookieInstance();
  }

  public get cookies() {
    return this._cookies;
  }

  private updateCookieInstance() {
    return (this._cookies = new Proxy(
      this.cookieService,
      this.handler(this._allowCookie)
    ));
  }

  private readonly handler = (
    allowCookie: CookieAccessStatus
  ): ProxyHandler<CookieService> => ({
    get(target, prop, receiver) {
      if (!allowCookie) return () => null;

      const targetFn = Reflect.get(target, prop, receiver);
      if (targetFn instanceof Function) {
        return function (...args: any[]) {
          // adding stage prefix to cookies name ...
          if (args[0]) args[0] = environment.stage + '-' + args[0]
          return targetFn.apply(target, args);
        };
      }
      return targetFn
    },
  });
}
