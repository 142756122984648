import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CookieAccessStatus,
  CookieManagerService,
} from './services/cookie-manager.service';
import { FullstoryService } from './services/fullstory.service';

@Component({
  selector: 'rmi-root',
  templateUrl: './rmi.component.html',
  styleUrls: ['./rmi.component.scss'],
})
export class RmiComponent implements OnInit {
  title = 'RoastMyIvr';
  showCookieDialog: boolean = false;

  private website = environment.website;
  private banner = environment.website + '/assets/banner.png';
  private metaTitle = 'RoastMyIvr | Audit IVRs automatically';
  private description =
    'Score your call centre IVR (interactive voice response) system in a few minutes and get tips on improving your customer experience automatically.';

  constructor(
    private cookieManager: CookieManagerService,
    private fullstoryService: FullstoryService,
    private metaTagService: Meta,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title
  ) {
    this.setWebsiteTitle();
    const allowCookie = this.cookieManager.allowedCookie;
    if (allowCookie === CookieAccessStatus.IsUndefined) {
      this.showCookieDialog = true;
    } else if (allowCookie === CookieAccessStatus.IsAllowed) {
      this.fullstoryService.setFullStory();
      this.fullstoryService.fullstoryInit.next();
    }
  }
  setWebsiteTitle() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data['title']) {
              return child.snapshot.data['title'];
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((data: any) => {
        if (data) {
          this.titleService.setTitle(data);
        }
      });
  }

  ngOnInit() {
    this.metaTagService.addTags([
      // Primary Meta Tags
      { name: 'title', content: this.title },
      { name: 'description', content: this.description },
      // Open Graph / Facebook
      { property: 'og:type', content: 'website' },
      { property: 'og:url', content: this.website },
      { property: 'og:title', content: this.metaTitle },
      { property: 'og:description', content: this.description },
      { property: 'og:image', content: this.banner },
      // Twitter
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:url', content: this.website },
      { property: 'twitter:title', content: this.metaTitle },
      { property: 'twitter:description', content: this.description },
      { property: 'twitter:image', content: this.banner },
    ]);
  }

  setCookie(allowCookie: CookieAccessStatus) {
    this.cookieManager.allowedCookie = allowCookie;
    if (allowCookie) {
      this.fullstoryService.setFullStory();
      this.fullstoryService.fullstoryInit.next();
    }
    this.showCookieDialog = false;
  }
}
