import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { IvrProcessStatus } from 'src/app/models/enums/IvrProcessStatus.enum';
import { Ivr } from 'src/app/models/ivr.model';
import { ModalService } from 'src/app/services/modal.service';
import { IvrDatasourceService } from 'src/app/shared/data/ivr-datasource.service';

@Component({
  selector: 'rmi-overall-report',
  templateUrl: './overall-report.component.html',
  styleUrls: ['./overall-report.component.scss'],
})
export class OverallReportComponent implements OnInit, OnDestroy {
  private readonly destroy$ = new Subject<void>();
  // @ts-ignore
  ivr: Ivr;
  ivrProcessStatus = IvrProcessStatus;

  get roastDate(): string {
    const date = new Date(this.ivr.finishProcessDate);
    return this.datePipe.transform(date, 'MMMM dd, yyyy')?.toString() || '';
  }

  get roastTimeIn24Hour(): string {
    const date = new Date(this.ivr.finishProcessDate);
    return this.datePipe.transform(date, 'H:mm')?.toString() || '';
  }

  get roastTimeIn12Hour(): string {
    const date = new Date(this.ivr.finishProcessDate);
    return (
      this.datePipe.transform(date, 'hh:mm a')?.toLowerCase().toString() || ''
    );
  }

  get getFullDateAndTime(): string {
    return `${this.roastDate}  •  AEST ${this.roastTimeIn24Hour} [${this.roastTimeIn12Hour}]`;
  }

  constructor(
    public datePipe: DatePipe,
    private modalService: ModalService,
    private ivrDataSource: IvrDatasourceService
  ) {}

  ngOnInit() {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => (this.ivr = ivr));
  }

  openImprovementRequestModal() {
    this.modalService.openImprovementRequestModal();
  }

  openEditOverallScoreModal() {
    this.modalService.openEditOverallScoreModal();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
