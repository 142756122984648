import { Component, EventEmitter, Output } from '@angular/core';
import { CookieAccessStatus } from 'src/app/services/cookie-manager.service';

@Component({
  selector: 'rmi-cookie-policy',
  templateUrl: './cookie-policy.component.html',
  styleUrls: ['./cookie-policy.component.scss'],
})
export class CookiePolicyComponent {
  @Output() setCookieEvent: EventEmitter<CookieAccessStatus> = new EventEmitter();

  setCookie(allowCookie: boolean) {
    this.setCookieEvent.emit(allowCookie? CookieAccessStatus.IsAllowed : CookieAccessStatus.IsDenied);
  }
}
