import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactUsComponent } from '../shared/components/modals/contact-us/contact-us.component';
import { ImprovementRequestComponent } from '../shared/components/modals/improvement-request/improvement-request.component';
import { ReportProblemComponent } from '../shared/components/modals/report-problem/report-problem.component';
import { ReportSharingComponent } from '../shared/components/modals/report-sharing/report-sharing.component';
import { EditOverallReportScoreComponent } from '../shared/components/modals/edit-overall-report-score/edit-overall-report-score.component';
import { EditSpeechQualityStatusComponent } from '../shared/components/modals/edit-speech-quality-status/edit-speech-quality-status.component';
import { EditComplexityReportStatusComponent } from '../shared/components/modals/edit-complexity-report-status/edit-complexity-report-status.component';
import { EditSoundQualityNoiseComponent } from '../shared/components/modals/edit-sound-quality-noise/edit-sound-quality-noise.component';
import { EditSoundQualityMusicComponent } from '../shared/components/modals/edit-sound-quality-music/edit-sound-quality-music.component';
import { EditSoundQualityLoudnessComponent } from '../shared/components/modals/edit-sound-quality-loudness/edit-sound-quality-loudness.component';
import { EditDiscoveredMenuContextComponent } from '../shared/components/modals/edit-discovered-menu-context/edit-discovered-menu-context.component';
import { EditRecommendationsComponent } from '../shared/components/modals/edit-recommendations/edit-recommendations.component';
import { EditQualitativeAnalysisComponent } from '../shared/components/modals/edit-qualitative-analysis/edit-qualitative-analysis.component';
import { ReportEditorNotesComponent } from '../shared/components/modals/report-editor-notes/report-editor-notes.component';
import { SaveEditorReportChangesComponent } from '../shared/components/modals/save-editor-report-changes/save-editor-report-changes.component';
import { AddNewMenuComponent } from '../shared/components/modals/add-new-menu/add-new-menu.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  defaultModalOption = {
    size: 'md',
    backdropClass: 'modal-backdrop',
    modalDialogClass: 'modal-dialog',
  };
  constructor(private modalService: NgbModal) {}

  openReportProblemModal() {
    this.modalService.open(ReportProblemComponent, this.defaultModalOption);
  }

  openImprovementRequestModal() {
    this.modalService.open(
      ImprovementRequestComponent,
      this.defaultModalOption
    );
  }

  openReportSharingModal() {
    this.modalService.open(ReportSharingComponent, this.defaultModalOption);
  }

  openContactUsModal() {
    this.modalService.open(ContactUsComponent, this.defaultModalOption);
  }

  openEditOverallScoreModal() {
    this.modalService.open(
      EditOverallReportScoreComponent,
      this.defaultModalOption
    );
  }

  openEditSpeechQualityStatusModal() {
    this.modalService.open(
      EditSpeechQualityStatusComponent,
      this.defaultModalOption
    );
  }

  openEditComplexityReportStatusModal() {
    this.modalService.open(
      EditComplexityReportStatusComponent,
      this.defaultModalOption
    );
  }

  openEditSoundQualityNoiseScoreModal() {
    this.modalService.open(
      EditSoundQualityNoiseComponent,
      this.defaultModalOption
    );
  }

  openEditSoundQualityMusicScoreModal() {
    this.modalService.open(
      EditSoundQualityMusicComponent,
      this.defaultModalOption
    );
  }

  openEditSoundQualityLoudnessScoreModal() {
    this.modalService.open(
      EditSoundQualityLoudnessComponent,
      this.defaultModalOption
    );
  }

  openEditMenuContextModal(menuId: string) {
    const modalRef = this.modalService.open(
      EditDiscoveredMenuContextComponent,
      this.defaultModalOption
    );
    modalRef.componentInstance.menuId = menuId;
  }

  openEditRecommendationModal(index: number | undefined) {
    const modalRef = this.modalService.open(
      EditRecommendationsComponent,
      this.defaultModalOption
    );
    modalRef.componentInstance.index = index;
  }

  openQualitativeAnalysisModal(index: number | undefined) {
    const modalRef = this.modalService.open(
      EditQualitativeAnalysisComponent,
      this.defaultModalOption
    );
    modalRef.componentInstance.index = index;
  }

  openReportEditorNoteModal() {
    const modalRef = this.modalService.open(
      ReportEditorNotesComponent,
      this.defaultModalOption
    );
  }

  openSaveEditorReportChangesModal() {
    const modalRef = this.modalService.open(
      SaveEditorReportChangesComponent,
      this.defaultModalOption
    );
  }

  openAddNewMenuModal(menuId: string) {
    const modalRef = this.modalService.open(
      AddNewMenuComponent,
      this.defaultModalOption
    );
    modalRef.componentInstance.menuId = menuId;
  }
}
