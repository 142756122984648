import { ReportProblem } from '../models/reportProblem.model';
import { ImprovementRequest } from '../models/improvementRequest.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Ivr } from '../models/ivr.model';
import { ShareReport } from '../models/shareReport.model';
import { ContactUs } from '../models/contactUs.model';
import { IvrReportVersionReqBody } from '../models/ivrReportVersionReqBody.model';
import { IvrReportVersion } from '../models/ivrReportVersion.model';

@Injectable({
  providedIn: 'root',
})
export class IvrService {
  private ivrAPI = environment.apiUrl + '/ivrs';
  private shareAPI = environment.apiUrl + '/share-report';
  private improvementsAPI = environment.appApiUrl + '/improvements';
  private problemsAPI = environment.appApiUrl + '/problems';
  private contactUsAPI = environment.apiUrl + '/contact-us';
  private saveIvrReportVersionAPI =
    environment.apiUrl + '/admin/report-versions';

  constructor(private http: HttpClient) {}

  getIvr(ivrId: string): Observable<Ivr> {
    return this.http.get<Ivr>(`${this.ivrAPI}/${ivrId}`);
  }

  saveIvrReportVersion(
    submission: IvrReportVersionReqBody
  ): Observable<IvrReportVersion> {
    return this.http.post<IvrReportVersion>(
      this.saveIvrReportVersionAPI,
      submission
    );
  }

  shareIvrByEmail(inputData: ShareReport): Observable<ShareReport> {
    return this.http.patch<ShareReport>(`${this.shareAPI}/${inputData.ivrId}`, {
      email: inputData.email,
    });
  }

  improvementRequest(inputData: ImprovementRequest): Observable<void> {
    return this.http.post<void>(this.improvementsAPI, inputData);
  }

  reportProblem(inputData: ReportProblem): Observable<void> {
    return this.http.post<void>(this.problemsAPI, inputData);
  }

  contactUs(inputData: ContactUs): Observable<void> {
    return this.http.post<void>(this.contactUsAPI, inputData);
  }
}
