import { Component, Input, OnInit } from '@angular/core';
import { ReportStatus } from 'src/app/models/report.model';
@Component({
  selector: 'rmi-report-status',
  templateUrl: './report-status.component.html',
  styleUrls: ['./report-status.component.scss'],
})
export class ReportStatusComponent {
  @Input() reportStatus: ReportStatus | undefined;
  constructor() {}
}
