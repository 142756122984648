<div class="w-100 d-flex flex-column">
  <h2
    i18n="@@ivr.recommendations.title"
    class="title text-md-start text-center mb-0"
  >
    Recommendations
  </h2>

  <ol class="d-flex flex-column mt-4 me-xl-32px me-lg-3 me-0 p-0 mb-0">
    <li
      *ngFor="let recommendation of recommendations; let i = index"
      class="d-flex flex-column flex-sm-row align-items-sm-start align-items-center mb-sm-4 mb-0"
    >
    <div class="d-flex flex-space">
      <p class="text text-center text-sm-start mt-2 mb-0 ms-0 ms-sm-32px">
        {{ recommendation }} 
      </p>
      <span class="d-flex flex-space justify-content-end align-items-center">
        <rmi-edit-report-button-by-admin
        (openRelatedEditModal)="openEditRecommendationModal(i)"
      >
      </rmi-edit-report-button-by-admin>
      <rmi-remove-report-detail-button 
      (deleteDetailOfReport)="deleteRecomendationOfReport(i)"
      >
      </rmi-remove-report-detail-button>
    </span>
    </div>
    </li>
    <div>
      <rmi-add-report-detail-button
      (openRelatedEditModal)="openEditRecommendationModal(undefined)"
    >
    </rmi-add-report-detail-button>
    </div>
  </ol>
</div>
