<div
  class="d-flex flex-column align-items-center bg-white rounded-3 position-relative modal-warper"
>
  <img
    (click)="dismissModal()"
    class="cross-btn"
    width="18"
    height="18"
    src="/assets/cross.svg"
  />
  <h2
    i18n="@@common.editDiscoveredMenuContext"
    class="mt-40px mb-4 text-center d-inline-block header"
  >
    Edit Discovered Menu Context
  </h2>

  <div class="w-100 p-3">
    <form
      class="form p-32px"
      *ngIf="ivr"
      [formGroup]="form"
      (ngSubmit)="submit()"
    >
      <div class="field">
        <label for="menuContext" class="form-label"
          >Menu Context:</label
        >
        <quill-editor
          id="menuContext"
          formControlName="menuContext"
          [modules]="module"
        ></quill-editor>
      </div>
      <div class="d-flex justify-content-center p-2">
        <button class="btn primary text-center" type="submit">
          <span i18n="@@common.save">Save</span>
        </button>
      </div>
    </form>
  </div>
</div>
