import { NgModule } from '@angular/core';
import {
  BrowserModule,
  BrowserTransferStateModule,
} from '@angular/platform-browser';

import { RmiRoutingModule } from './rmi-routing.module';
import { RmiComponent } from './rmi.component';
import { HttpClientModule } from '@angular/common/http';
import { IvrAnalysisReportModule } from './pages/ivr-analysis-report/ivr-analysis-report.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CookieService } from 'ngx-cookie-service';

@NgModule({
  declarations: [RmiComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    BrowserAnimationsModule,
    RmiRoutingModule,
    HttpClientModule,
    IvrAnalysisReportModule,
    SharedModule,
  ],
  providers: [
    CookieService,
  ],
  bootstrap: [RmiComponent],
})
export class RmiModule {}
