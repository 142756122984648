import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { Ivr } from 'src/app/models/ivr.model';
import { ModalService } from 'src/app/services/modal.service';
import { IvrDatasourceService } from 'src/app/shared/data/ivr-datasource.service';

@Component({
  selector: 'rmi-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  private readonly destroy$ = new Subject<void>();
  extraInformation: string = '';
  canEditReport: boolean = false;
  constructor(
    private modalService: ModalService,
    private ivrDataSource: IvrDatasourceService
  ) {}

  ngOnInit() {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => {
        this.extraInformation =
          ivr.ivrReportVersion!.ivrReport.extraInformation;
        this.canEditReport = ivr.canEditReport;
      });
  }

  openReportEditorNoteModal() {
    this.modalService.openReportEditorNoteModal();
  }

  openSaveEditorReportChangesModal() {
    this.modalService.openSaveEditorReportChangesModal();
  }

  openImprovementRequestModal() {
    this.modalService.openImprovementRequestModal();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }
}
