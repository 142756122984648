import { Component, Input } from '@angular/core';

@Component({
  selector: 'rmi-analysis-shape',
  templateUrl: './analysis-shape.component.html',
  styleUrls: ['./analysis-shape.component.scss'],
})
export class AnalysisShapeComponent {
  @Input() haveProblem: boolean = false;
}
