export interface Report {
  overallResult: ReportStatus;
  speechLoudness: ReportStatus;
  speechQuality: ReportStatus;
  audioMusic: ReportStatus;
  audioNoise: ReportStatus;
  complexity: ReportStatus;
  qualitativeAnalysis: QualitativeAnalysisStatus[];
  recommendations: string[];
  extraInformation: string;
}

export interface ReportStatus {
  statusText: string;
  color: string;
  colorWithOpacity: string;
  stars: number;
  percent?: number;
  widget?: string;
  description?: string;
}

export interface QualitativeAnalysisStatus {
  text: string;
  description?: string;
  hasIssue: boolean;
  numberOfIssues?: number;
}

export enum ReportStatusName {
  Excellent = 'Excellent',
  Good = 'Good',
  CanBeImproved = 'Can be improved',
  NeedsAdjustment = 'Needs adjustment',
  NeedsImmediateAttention = 'Needs immediate attention',
  NotAvailable = 'Not Available',
}

export enum ReportStatusColor {
  Green = '#08AD59',
  Orange = '#F37400',
  Red = '#E42535',
}

export enum ReportStatusColorWithOpacity {
  Green = 'rgba(8, 173, 89, 0.1)',
  Orange = 'rgba(243, 116, 0, 0.1)',
  Red = 'rgba(228, 37, 53, 0.1)',
}

export enum ReportStatusComplexityWidget {
  Excellent = 'excellent-widget.svg',
  Needs_adjustment = 'needs-adjustment-widget.svg',
  Needs_immediate_attention = 'needs-immediate-attention-widget.svg',
  Not_available = 'complexity-error.svg',
}

export const getReportStatusNameAsKeyValue = (includeNotAvailable = false) => {
  let options = Object.entries(ReportStatusName).map(([key, value]) => ({
    key,
    value,
  }));
  if (!includeNotAvailable) {
    options = options.filter(
      (option) => option.value !== ReportStatusName.NotAvailable
    );
  }
  return options;
};

export const getReportStatusColorAsKeyValue = () => {
  const options = Object.entries(ReportStatusColor).map(([key, value]) => ({
    key,
    value,
  }));
  return options;
};

export const getReportStatusComplexityWidgetAsKeyValue = () => {
  const options = Object.entries(ReportStatusComplexityWidget).map(
    ([key, value]) => ({
      key: key.replace(/\_/gi, ' '),
      value,
    })
  );
  return options;
};

export const getReportStatusStarsAsKeyValue = () => {
  let options: {
    key: string;
    value: number;
  }[] = [];
  for (let index = 5; index >= 1; index -= 0.5) {
    options.push({ key: index.toString(), value: index });
  }
  return options;
};

export const getReportStatusColorWithOpacityValue = (
  colorStatus: ReportStatusColor
): string => {
  switch (colorStatus) {
    case ReportStatusColor.Green:
      return ReportStatusColorWithOpacity.Green;
    case ReportStatusColor.Orange:
      return ReportStatusColorWithOpacity.Orange;
    case ReportStatusColor.Red:
      return ReportStatusColorWithOpacity.Red;

    default:
      return ReportStatusColorWithOpacity.Green;
  }
};

export const getReportStatusStarsFromStatusName = (
  status: ReportStatusName
): number => {
  switch (status) {
    case ReportStatusName.Excellent:
      return 5;
    case ReportStatusName.Good:
      return 4;
    case ReportStatusName.CanBeImproved:
      return 3;
    case ReportStatusName.NeedsAdjustment:
      return 2;
    case ReportStatusName.NeedsImmediateAttention:
      return 1;
    case ReportStatusName.NotAvailable:
      return 0;

    default:
      return 5;
  }
};

export const getOrderOptionsAsKeyValue = (noOfRecord : number) => {
  let options: {
    key: string;
    value: number;
  }[] = [];
  for (let index = 1; index <= noOfRecord ; index ++) {
    options.push({ key: index.toString(), value: index-1 });
  }
  return options;
};
