import { IvrDatasourceService } from '../../../data/ivr-datasource.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  getReportStatusColorAsKeyValue,
  getReportStatusColorWithOpacityValue,
  getReportStatusNameAsKeyValue,
  getReportStatusStarsAsKeyValue,
  getReportStatusStarsFromStatusName,
  ReportStatus,
  ReportStatusColor,
  ReportStatusColorWithOpacity,
  ReportStatusName,
} from 'src/app/models/report.model';
import { Ivr } from 'src/app/models/ivr.model';

@Component({
  selector: 'rmi-edit-speech-quality-status',
  templateUrl: './edit-speech-quality-status.component.html',
  styleUrls: ['./edit-speech-quality-status.component.scss'],
})
export class EditSpeechQualityStatusComponent implements OnDestroy, OnInit {
  private readonly destroy$ = new Subject<void>();
  showScoreAndColor = false;
  colorOptions = ['Green', 'Yellow', 'Red'];
  get reportStatusNameOptions() {
    return getReportStatusNameAsKeyValue(true);
  }
  get reportStatusColorOptions() {
    return getReportStatusColorAsKeyValue();
  }
  get reportStatusStarOptions() {
    return getReportStatusStarsAsKeyValue();
  }

  // @ts-ignore
  form: FormGroup;
  errorMessage: string = '';
  // @ts-ignore
  ivr: Ivr;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private ivrDataSource: IvrDatasourceService
  ) {}

  ngOnInit(): void {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => {
        this.form = this.fb.group({
          statusText: [
            ivr.ivrReportVersion!.ivrReport.speechQuality.statusText,
            [Validators.required],
          ],
          color: [
            ivr.ivrReportVersion!.ivrReport.speechQuality.color,
            [Validators.required],
          ],
          stars: [
            ivr.ivrReportVersion!.ivrReport.speechQuality.stars,
            [Validators.required],
          ],
          percent: [
            Math.ceil(
              ivr.ivrReportVersion!.ivrReport.speechQuality.percent || 1
            ),
            [Validators.required],
          ],
          description: [
            ivr.ivrReportVersion!.ivrReport.speechQuality.description,
            [Validators.required],
          ],
          colorWithOpacity: [
            ivr.ivrReportVersion!.ivrReport.speechQuality.colorWithOpacity,
            [Validators.required],
          ],
        });
        this.onChangeStatus(
          ivr.ivrReportVersion!.ivrReport.speechQuality.statusText
        );
        this.ivr = ivr;
      });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();

      const data: ReportStatus = {
        color: this.form.controls['color'].value,
        colorWithOpacity: this.form.controls['colorWithOpacity'].value,
        stars: this.form.controls['stars'].value,
        statusText: this.form.controls['statusText'].value,
        percent: this.form.controls['percent'].value,
        description: this.form.controls['description'].value,
      };
      this.ivr.ivrReportVersion!.ivrReport.speechQuality = data;
      this.ivrDataSource.ivr$.next(this.ivr);
      this.closeModal();
    }
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  closeModal() {
    this.activeModal.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onInput(): void {
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res === 'INVALID') {
        this.form.markAllAsTouched();
      }
    });
  }

  onBlur(): void {
    this.form.markAsUntouched();
  }

  onChangeStatus(status: string): void {
    if (status !== ReportStatusName.NotAvailable) {
      this.showScoreAndColor = true;
      this.form.controls['stars'].setValue(
            getReportStatusStarsFromStatusName(<ReportStatusName>status)
          );
    } else {
      this.showScoreAndColor = false;
      this.setNotAvailableStatusForSpeechQualityStatus();
    }
  }

  setNotAvailableStatusForSpeechQualityStatus() {
    this.form.controls['color'].setValue(ReportStatusColor.Red);
    this.form.controls['colorWithOpacity'].setValue(ReportStatusColorWithOpacity.Red);
    this.form.controls['statusText'].setValue(ReportStatusName.NotAvailable);
    this.form.controls['stars'].setValue(0);
    this.form.controls['percent'].setValue(0);
    this.form.controls['description'].setValue('Failed to analyse');
  }

  onChangeColor(colorStatus: ReportStatusColor): void {
    this.form.controls['colorWithOpacity'].setValue(
      getReportStatusColorWithOpacityValue(colorStatus)
    );
  }
  identify(index:number, item:any){
    return item.key;
  }

  module = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],

      [{ header: 1 }, { header: 2 }], // custom button values
      [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
      [{ direction: 'rtl' }], // text direction

      [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
      [{ header: [1, 2, 3, 4, 5, 6, false] }],

      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ font: [] }],
      [{ align: [] }],

      ['clean'], // remove formatting button

      ['link'], // link and image, video
    ],
  };
}
