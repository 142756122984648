import { Component, Input } from '@angular/core';
import { ReportStatus } from 'src/app/models/report.model';

@Component({
  selector: 'rmi-circle-progress',
  templateUrl: './circle-progress.component.html',
  styleUrls: ['./circle-progress.component.scss'],
})
export class CircleProgressComponent {
  // @ts-ignore
  @Input() reportStatus: ReportStatus;

  constructor() {}
}
