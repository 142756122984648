import { IvrDatasourceService } from '../../../data/ivr-datasource.service';
import { Component, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Ivr } from 'src/app/models/ivr.model';
import { getOrderOptionsAsKeyValue } from 'src/app/models/report.model';

@Component({
  selector: 'rmi-edit-recommendations',
  templateUrl: './edit-recommendations.component.html',
  styleUrls: ['./edit-recommendations.component.scss'],
})
export class EditRecommendationsComponent implements OnDestroy {
  private readonly destroy$ = new Subject<void>();
  @Input() index: number | undefined;

  // @ts-ignore
  form: FormGroup;
  errorMessage: string = '';
  // @ts-ignore
  ivr: Ivr;

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    private ivrDataSource: IvrDatasourceService
  ) {}

  get reportRecommendationsOrderOptions() {
    const reportRecommendations =
      this.ivr.ivrReportVersion!.ivrReport.recommendations;
    if (this.index === undefined) {
      return getOrderOptionsAsKeyValue(reportRecommendations.length + 1);
    } else {
      return getOrderOptionsAsKeyValue(reportRecommendations.length);
    }
  }

  ngOnInit() {
    this.ivrDataSource.ivr$
      .pipe(takeUntil(this.destroy$))
      .subscribe((ivr: Ivr) => {
        this.form = this.fb.group({
          recommendation: [
            ivr.ivrReportVersion!.ivrReport.recommendations[this.index ?? 0],
            [Validators.required],
          ],
          recommendationPriority: [this.index, [Validators.required]],
        });
        this.ivr = ivr;
      });
  }

  public submit(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.form.disable();
      const reportRecommendations =
        this.ivr.ivrReportVersion!.ivrReport.recommendations;
      const priority = this.form.controls['recommendationPriority'].value;
      if (this.index === undefined) {
        reportRecommendations.push(this.form.controls['recommendation'].value);
        this.shiftArrayItems(
          reportRecommendations,
          priority,
          reportRecommendations.length - 1
        );
      } else {
        reportRecommendations[this.index] =
          this.form.controls['recommendation'].value;
        this.shiftArrayItems(reportRecommendations, priority, this.index);
      }
      this.ivrDataSource.ivr$.next(this.ivr);
      this.closeModal();
    }
  }

  closeModal() {
    this.activeModal.close();
  }

  dismissModal() {
    this.activeModal.dismiss();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.unsubscribe();
  }

  onInput(): void {
    this.form.statusChanges.pipe(takeUntil(this.destroy$)).subscribe((res) => {
      if (res === 'INVALID') {
        this.form.markAllAsTouched();
      }
    });
  }

  onBlur(): void {
    this.form.markAsUntouched();
  }

  identify(index: number, item: any) {
    return item.key;
  }

  shiftArrayItems(sourceArray: string[], to: number, from: number): string[] {
    return sourceArray.splice(to, 0, sourceArray.splice(from, 1)[0]);
  }
}
