//@ts-nocheck
import { isPlatformBrowser } from '@angular/common';
import {
  OnInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';

declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}

@Component({
  selector: 'rmi-waveform',
  templateUrl: './waveform.component.html',
  styleUrls: ['./waveform.component.scss'],
})
export class WaveformComponent implements OnInit, OnDestroy {
  @Input()
  audioSrc: string = '';

  @ViewChild('audioCanvas')
audioCanvas: ElementRef<HTMLCanvasElement>;
drawingContext: CanvasRenderingContext2D | null;
waveContext: CanvasRenderingContext2D | null;
hlWaveContext: CanvasRenderingContext2D | null;
hoverWaveContext: CanvasRenderingContext2D | null;


width: number;
height: number = 32;
barLineWidth: number = 2;
barLineSpaceBetween: number = 1;

sizeObserver: ResizeObserver;
get barLineCount(): number {
  return (
    Math.floor(this.width ?? 0 / (this.barLineWidth + this.barLineSpaceBetween)) -
    1
  );
}

barLineColor: string = 'rgba(69, 123, 157, 0.3)';
barLineProgressColor: string = '#E42535';
barLineHoverColor: string = 'rgba(228, 37, 53, 0.3)';

playTimer: ReturnType<typeof setTimeout>;
playTime = 0;
playStart: number;
previousPlayTime: number;

audioContext: AudioContext;
audioSource: AudioBufferSourceNode;
audioBuffer: AudioBuffer;
sampledData: number[] = [];

loaded: boolean = false;
playing: boolean = false;
isAudioAvailable: boolean = false;
isLoading: boolean = true;

mouseX: number = 0;

constructor(
  @Inject(PLATFORM_ID) private platformId: Object,
  private elementRef: ElementRef,
  private cd: ChangeDetectorRef
) {}

ngOnInit() {
  this.sizeObserver = new ResizeObserver((entries) => {
    const waveForm: any = entries[0].target;
    this.width = waveForm.offsetWidth - 80;
    if (this.audioCanvas) {
      this.audioCanvas.nativeElement.width = this.width;
    }
    if (this.loaded) {
      this.generateWaveFormLayout();
    }
    if (isPlatformBrowser(this.platformId)) {
      this.createWaveformAndAudioPlayer();
    }
  });
  this.sizeObserver.observe(this.elementRef.nativeElement);
}

createWaveformAndAudioPlayer() {
  window.AudioContext = window.AudioContext || window.webkitAudioContext;
  this.audioContext = new AudioContext();
  this.drawingContext = this.audioCanvas?.nativeElement.getContext('2d');
  if (this.audioCanvas) {
    this.audioCanvas.nativeElement.width = this.width ?? 0;
  }
  this.loadAudioFile();
}

loadAudioFile(): void {
  const request = new XMLHttpRequest();
  request.open('GET', this.audioSrc, true);
  request.responseType = 'arraybuffer';

  request.onload = () => {
    if (request.status == 200) {
      this.isAudioAvailable = true;
      void this.audioContext?.decodeAudioData(
        request.response,
        (buffer: AudioBuffer) => {
          this.audioBuffer = buffer;
          this.audioSource = this.audioContext?.createBufferSource();
          if (this.audioSource) {
            this.audioSource.buffer = this.audioBuffer;
            this.audioSource.connect(this.audioContext.destination);
          }

          this.cd.detectChanges();
          this.generateWaveFormLayout();
        }
      );
    } else {
      this.isAudioAvailable = false;
      this.isLoading = false;
    }
  };
  request.send();
}

generateWaveFormLayout(): void {
  this.setAudioBufferSamples(this.audioBuffer.getChannelData(0));
  this.generateLayers();
  this.loaded = true;
  this.drawCanvas();
}

setAudioBufferSamples(audioBuffer: Float32Array): void {
  this.sampledData = [];
  const blockSize = Math.floor(audioBuffer.length / this.barLineCount);
  for (let i = 0; i < this.barLineCount; i++) {
    let blockStart = blockSize * i;
    let avgSample = 0;
    for (let j = 0; j < blockSize; j++) {
      avgSample = avgSample + Math.abs(audioBuffer[blockStart + j]);
    }
    const scaledSample = avgSample / blockSize;
    this.sampledData.push(scaledSample);
  }

  const multiplier = Math.pow(Math.max(...this.sampledData), -1);
  this.sampledData = this.sampledData.map(
    (n) => n * multiplier * this.height
  );
}

generateLayers(): void {
  const waveCanvas = document.createElement('canvas');
  waveCanvas.width = this.width;
  waveCanvas.height = this.height;
  this.waveContext = waveCanvas.getContext('2d');

  const hlWaveCanvas = document.createElement('canvas');
  hlWaveCanvas.width = this.width;
  hlWaveCanvas.height = this.height;
  this.hlWaveContext = hlWaveCanvas.getContext('2d');

  const hoverWaveCanvas = document.createElement('canvas');
  hoverWaveCanvas.width = this.width;
  hoverWaveCanvas.height = this.height;
  this.hoverWaveContext = hoverWaveCanvas.getContext('2d');

  let currentX = 0;

  this.sampledData.forEach((data, index) => {
    //Have to draw a point here.
    const scaledSample = data;
    if (this.waveContext) {
      this.createBarLine(
        this.waveContext,
        currentX,
        (this.height - scaledSample) / 2,
        this.barLineWidth,
        scaledSample,
        this.barLineColor
      );
    }
    if (this.hlWaveContext) {
      this.createBarLine(
        this.hlWaveContext,
        currentX,
        (this.height - scaledSample) / 2,
        this.barLineWidth,
        scaledSample,
        this.barLineProgressColor
      );
    }

    if (this.hoverWaveContext) {
      this.createBarLine(
        this.hoverWaveContext,
        currentX,
        (this.height - scaledSample) / 2,
        this.barLineWidth,
        scaledSample,
        this.barLineHoverColor
      );
    }
    currentX = currentX + this.barLineWidth + this.barLineSpaceBetween;
  });
}

createBarLine(
  ctx: CanvasRenderingContext2D,
  upperLeftCornerX: number,
  upperLeftCornerY: number,
  width: number,
  height: number,
  color: string
): void {
  ctx.save();
  ctx.fillStyle = color;
  ctx.fillRect(upperLeftCornerX, upperLeftCornerY, width, height);
  ctx.restore();
}

drawCanvas(): void {
  const backCanvas = document.createElement('canvas');
  backCanvas.width = this.width;
  backCanvas.height = this.height;
  const backContext = backCanvas.getContext('2d');
  if (backContext) {
    backContext.clearRect(0, 0, this.width, this.height);
  }
  if (this.drawingContext) {
    this.drawingContext.clearRect(0, 0, this.width, this.height);
  }

  if (this.loaded) {
    let waveImage!: ImageData;
    let hlWaveImage!: ImageData;
    let hoverWaveImage!: ImageData;
    if (this.waveContext) {
      waveImage = this.waveContext.getImageData(
        0,
        0,
        this.width,
        this.height
      );
    }
    if (this.hlWaveContext) {
      hlWaveImage = this.hlWaveContext.getImageData(
        0,
        0,
        this.width,
        this.height
      );
    }
    if (this.hoverWaveContext) {
      hoverWaveImage = this.hoverWaveContext.getImageData(
        0,
        0,
        this.width,
        this.height
      );
    }
    if (backContext) {
      backContext.putImageData(waveImage, 0, 0);
    }
    const hlWaveX = (this.width / this.audioBuffer.duration) * this.playTime;
    if (backContext) {
      backContext.putImageData(hlWaveImage, 0, 0, 0, 0, hlWaveX, this.height);
      if (this.mouseX > hlWaveX) {
        backContext.putImageData(
          hoverWaveImage,
          0,
          0,
          hlWaveX,
          0,
          this.mouseX - hlWaveX,
          this.height
        );
      }
    }

    //Render the frame.
    let image!: ImageData;
    if (backContext) {
      image = backContext.getImageData(0, 0, this.width, this.height);
    }
    if (this.drawingContext) {
      this.drawingContext.putImageData(image, 0, 0);
    }
  }
}

waveFormMouseMove(event: MouseEvent): void {
  const target = event.target as Element;
  this.mouseX = event.clientX - target.getBoundingClientRect().left;
  this.drawCanvas();
}

waveFormMouseLeave(): void {
  this.mouseX = 0;
  this.drawCanvas();
}

waveFormClick(): void {
  if (this.mouseX > 0) {
    const selectedTime =
      (this.mouseX * this.audioBuffer.duration) / this.width;
    if (this.playing) {
      this.pause();
      this.playTime = selectedTime;
      this.play();
    } else {
      this.playTime = selectedTime;
    }
  }
}

togglePlay(): void {
  if (this.playing) {
    this.pause();
  } else {
    this.play();
  }
}

play(): void {
  if (this.loaded && !this.playing) {
    this.audioSource = this.audioContext.createBufferSource();
    this.audioSource.buffer = this.audioBuffer;
    this.audioSource.connect(this.audioContext.destination);
    this.audioSource.start(0, this.playTime);

    this.playing = true;
    this.playStart = Date.now();
    this.previousPlayTime = this.playTime;
    this.playTimer = setInterval(() => {
      const currentTime = Date.now();
      this.playTime =
        (currentTime - this.playStart) / 1000 + this.previousPlayTime;
      this.drawCanvas();
      if (this.audioBuffer.duration <= this.playTime) {
        this.onAudioEnded();
      }
    }, 100);
  }
}

pause(): void {
  if (this.playing) {
    this.audioSource.disconnect();
    this.audioSource.stop();
    this.playing = false;
    clearInterval(this.playTimer);
  }
}

onAudioEnded(): void {
  this.pause();
  this.playTime = 0;
  this.drawCanvas();
}

ngOnDestroy(): void {
  this.sizeObserver.disconnect();
  clearInterval(this.playTimer);
}
}
